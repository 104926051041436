import React,{ useEffect,useMemo,useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCardStyle,Form,FormError,FormItem,FormLabel,FormRow,Input,PrimaryButton,ResetButton,
} from '../styles/CommonStyles'
import { useNavigate } from 'react-router-dom'
import { useDoc } from '../hooks/useDoc'
import { addDoc,collection,doc,setDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'
import SearchableDropwdown from './SearchableDropdown'
import { useCollection } from '../hooks/useCollection'
import { db } from '../services/firebase'
import { FIREBASE_ERROR_MESSAGES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object({
  selectedVenue: yup.object({ value: yup.string().required(`This field is required`) }),
  number: yup.number().required().positive(`Number must be a positive number`).integer(`Number must be an integer`).typeError(`Please enter a valid number`),
}).required()

export const CloakroomAddOrUpdate = ({ id }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(schema) })
  const [venues] = useCollection(`venues`)
  const cloakroom = useDoc(`cloakrooms/${id}`)
  const navigate = useNavigate()

  const venueOptions = useMemo(() => venues.map(item => ({ value: item.id,label: item.name })),[venues])

  useEffect(() => {
    if(cloakroom.id) {
      setValue(`selectedVenue`,venueOptions.find(item => item.value === cloakroom.venueId))
      setValue(`number`,cloakroom.number)
    }
    // else         
    //   navigate(`/404`)
  },[setValue,venueOptions,cloakroom])

  const handleSave = async({ selectedVenue,number }) => {
    try {
      if(id) {
        await setDoc(doc(db,`cloakrooms`,id),{ number },{ merge: true })
        toast.success(`Cloakroom updated successfully`)
      } else {
        await addDoc(collection(db,`cloakrooms`),{
          venueId: selectedVenue.value,
          number,
        })
        toast.success(`Cloakroom saved successfully`)
      }

      navigate(`/cloakrooms`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <>
      <LeftContainer>
        <Form onSubmit={handleSubmit(handleSave)}>
          <FormRow>
            <FormItem>
              <FormLabel>
                Venue
              </FormLabel>
              <Controller
                control={control}
                name="selectedVenue"
                render={({ field }) => (<SearchableDropwdown
                  options={venueOptions}
                  placeholder="Select Venue"
                  value={field.value}
                  isDisabled={!!id}
                  onChange={field.onChange}/>)}/>
              <FormError>{errors.selectedVenue?.value?.message}</FormError>
            </FormItem>
            <FormItem>
              <FormLabel>
                Number
              </FormLabel>
              <Input type="number" step="any" {...register(`number`)} />
              <FormError>{errors.number?.message}</FormError>
            </FormItem>
          </FormRow>
          <FormRow>
            <Button $loading={isSubmitting}>{id ? `Update Cloakroom` : `Create Cloakroom`}</Button>
          </FormRow>
        </Form>
      </LeftContainer>
      <RightContainer>
      </RightContainer>
    </>
  )
}

const LeftContainer = styled.div`
  ${DefaultCardStyle}
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  color: var(--onSurface);
`
