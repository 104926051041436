import { getDepthValue } from './getDepthValue'

export const sortBy = (field,isReverse,primer) => {
  const key = primer ?
    function(x) {
      return primer(getDepthValue(x,field,true)) || ``
    } :
    function(x) {
      return getDepthValue(x,field,true) || ``
    }

  isReverse = !isReverse ? 1 : -1

  return function(a,b) {
    return a = key(a),b = key(b),isReverse * ((a > b) - (b > a))
  }
}
