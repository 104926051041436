import styled from 'styled-components'
import { useAuth } from '../hooks/useAuth'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { USER_ROLES } from '../data/constants'
import { BiMap } from 'react-icons/bi'
import { useCurrentVenue } from '../hooks/useCurrentVenue'
import { useCollection } from '../hooks/useCollection'
import { AreaButton,IconButton } from '../styles/CommonStyles'
import Modal from './Modal'
import { useState,useMemo } from 'react'
import { TbHanger } from 'react-icons/tb'
import { BsDoorClosed } from 'react-icons/bs'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import ButtonWithBadge from './ButtonWithBadge'
import { useQueryCollection } from '../hooks/useQueryCollection'
import OrderDetailCard from './OrderDetailCard'
import { CustomContentLoader } from './CustomContentLoader'

const UserGreeting = props => {
  const [venues] = useCollection(`venues`)
  const currentUser = useCurrentUser()
  const [currentVenue] = useCurrentVenue()
  const [orders] = useQueryCollection(`orders`,[[`hangingId`,`==`,currentVenue?.hanging?.id]])
  const [showModal,setShowModal] = useState(false)
  const [showOrdersModal,setShowOrdersModal] = useState(false)

  const { isInitialized,displayName,photoURL,role } = currentUser

  if(!isInitialized)
    return (
      <Container>
        <InnerContainer>
          <CustomContentLoader width="100%" height="100">
            <rect x="0" y="0" rx="4" ry="4" width="48" height="48" />
            <rect x="0" y="56" rx="4" ry="4" width="60" height="14" />
            <rect x="0" y="78" rx="4" ry="4" width="120" height="14" />
          </CustomContentLoader>
        </InnerContainer>
      </Container>
    )

  return (
    <Container {...props}>
      {currentUser.id && <InnerContainer>
        {photoURL ? (
          <ImageContainer>
            <Image src={photoURL} />
          </ImageContainer>
        ) : <></>}
        <Content>
          <Text>Welcome,</Text>
          {displayName ? <Name>{displayName}</Name> : <ProfileLink to={`/profile`}>Edit Profile</ProfileLink>}
          {role && role !== USER_ROLES.USER ? <Pill>{role}</Pill> : <></> }
        </Content>
      </InnerContainer>}
      {(!role || role === USER_ROLES.USER) && currentVenue.id ? 
        <>
          <InnerContainer>
            <Location>
              <BiMap />
              <Text>{currentVenue.name}</Text>
            </Location>
          </InnerContainer>
          <InnerContainer>
            <Location>
              <BsDoorClosed />
              <Text>{currentVenue.cloakroom.number}</Text>
            </Location>
          </InnerContainer>
          <InnerContainer>
            <Location>
              <TbHanger />
              <Text>{currentVenue.hanger.number}</Text>
              {orders.length ?
                <ButtonWithBadge onClick={() => setShowOrdersModal(true)} style={{ marginLeft: `auto` }} Component={IconButton} count={orders.length} compact>
                  <AiOutlineShoppingCart size={18} />
                </ButtonWithBadge> : <></>}
            </Location>
            <Modal scroll title={`Orders`} showModal={showOrdersModal} setShowModal={setShowOrdersModal}>
              {orders.map(order => {
                return <ModalOrderDetailCard key={order.id} {...order} />
              })}
            </Modal>
          </InnerContainer>
        </> : <></>}
      {role === USER_ROLES.CLOAKROOM && currentUser.venues?.length ? 
        <InnerContainer noSpace>
          <LocationButton onClick={() => setShowModal(true)}>
            <BiMap />
            <Text>{currentUser.venues.length > 1 ? `${currentUser.venues.length} locations` : venues.find(venue => venue.id === currentUser.venues[0]).name}</Text>
          </LocationButton>
          <Modal title="Locations" showModal={showModal} setShowModal={setShowModal}>
            {currentUser.venues.map(item => (
              <LocationItem key={item}>{venues.find(venue => venue.id === item).name}</LocationItem>
            ))}
          </Modal>
        </InnerContainer> : <></>}
    </Container>
  )
}

export default UserGreeting

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: ${p => p.noSpace ? `0px` : `16px 24px` };
  border-top: 1px solid var(--dividerOnBackground);

  &:last-child {
    border-bottom: 1px solid var(--dividerOnBackground);
  }
`
const ImageContainer = styled.div`
  align-self: flex-start;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  font-size: 14px;
  color: var(--onBackground);
`
const Text = styled.div`
  font-size: 12px;
`
const Name = styled.div`

`
const ProfileLink = styled(Link)`
  text-decoration: underline;
`
const Pill = styled.div`
  display: inline-flex;
  padding: 2px 8px;
  font-size: 10px;
  border-radius: 12px;
  color: var(--background);
  background-color: var(--light);
`
const Location = styled.div`
  display: flex;
  gap: 8px;
  color: var(--onBackground);
`
const LocationButton = styled(AreaButton)`
  flex: 1;
  display: flex;
  gap: 8px;
  padding: 16px 24px;
  color: var(--onBackground);
  transition: all .2s;

  &:hover {
    background-color: var(--dividerOnBackground);
  }
`
const LocationItem = styled.div`
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dividerOnSurface);
  }

  &:last-child {
    padding-bottom: 0;
  }
`
const ModalOrderDetailCard = styled(OrderDetailCard)`
  padding: 16px;
  background-color: #111;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`
