import React from 'react'
import styled from 'styled-components'

const ButtonWithBadge = ({ Component,children,count,compact,...rest }) => {
  return (
    <Component {...rest}>
      <Container>
        {children}
        {count ? <Count $compact={compact}>{count > 9 ? `9+` : count}</Count> : <></>}
      </Container>
    </Component>
  )
}

export default ButtonWithBadge

const Container = styled.div`
  position: relative;
  display: flex;
`
const Count = styled.div`
  position: absolute;
  top: -4px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 16px;
  font-size: 11px;
  background-color: red;
  color: #fff;

  ${p => p.$compact && `
    width: 14px;
    height: 14px;
    font-size: 8px;
  `}
`
