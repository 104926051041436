import React from 'react'
import { Link,useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '../components/Button'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import ButtonWithBadge from '../components/ButtonWithBadge'
import { useCartCount } from '../hooks/useCart'

export const Landing = () => {
  const navigate = useNavigate()
  const [cartCount] = useCartCount()

  return (
    <Container>
      <Navbar>
        <CompanyName>
          Stash AI
        </CompanyName>
        <ButtonGroup>
          <NavbarLink to={`/home`}>Catalog</NavbarLink>

          <ButtonWithBadge Component={NavbarLink} to={`/cart`} count={cartCount}>
            <Cart color="#ffffff" size={30} />
          </ButtonWithBadge>
        
          <LoginButton to={`/login`}>Login</LoginButton>
        </ButtonGroup>
      </Navbar>
      <Content>
        <Message>
          Welcome to Stash AI
        </Message>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  flex-direction:column;
  background-color: var(--background);
  height:100vh;
`

const Navbar = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding: 1em;
`

const CompanyName = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  color: white;
`

const ButtonGroup = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  gap:20px;
`
const NavbarLink = styled(Link)`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 24px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-size: 1em;
  color: var(--onBackground);
  border-radius: 3em;
`
const LoginButton = styled(Link)`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding:.8em 2em;
  font-size: 24px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  border: none;
  font-size: 1em;
  background-color: var(--white);
  color: var(--onWhite);
  border-radius: 3em;
`

const Content = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  gap:20px;
  margin-top:20px;
`

const Message = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  color: white;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Cart = styled(AiOutlineShoppingCart)`
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
        color: var(--light);
        scale: 1.1;
    }

    @media screen and (max-width: 425px) {
        margin-bottom: 3rem;
    }
`
