import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { RiSecurePaymentLine } from 'react-icons/ri'
import {
  DefaultCardStyle,Form,FormError,FormItem,FormLabel,FormRow,Input,PrimaryButton,ResetButton,
} from '../styles/CommonStyles'
import Dropzone from './Dropzone'
import { useNavigate } from 'react-router-dom'
import { useDoc } from '../hooks/useDoc'
import { addDoc,collection,doc,setDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from '../services/firebase'
import { FIREBASE_ERROR_MESSAGES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object({
  title: yup.string().required(`This field is required`).max(80,`This field must be at most 80 characters`),
  price: yup.number().required().positive(`Price must be a positive number`).typeError(`Please enter a valid price`),
  thumbnail: yup.string().required(`This field is required`),
}).required()

const ProductAddOrUpdate = ({ id }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(schema) })
  const product = useDoc(`products/${id}`)
  const navigate = useNavigate()

  useEffect(() => {
    if(product.id) {
      setValue(`title`,product.title)
      setValue(`price`,product.price)
      setValue(`thumbnail`,product.thumbnail)
    } 
    // else         
    //   navigate(`/404`)
    
  },[setValue,product])

  const handleSave = async({ title,price,thumbnail }) => {
    const productToSave = {
      title,
      price,
      thumbnail,
    }

    try {
      if(id) {
        await setDoc(doc(db,`products`,id),productToSave)
        toast.success(`Product updated successfully`)
      } else {
        await addDoc(collection(db,`products`),productToSave)
        toast.success(`Product saved successfully`)
      }

      navigate(`/products`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <>
      <LeftContainer>
        <Form onSubmit={handleSubmit(handleSave)}>
          <FormRow>
            <FormItem>
              <FormLabel>
                Title
              </FormLabel>
              <Input {...register(`title`)} />
              <FormError>{errors.title?.message}</FormError>
            </FormItem>
            <FormItem>
              <FormLabel>
                Price
              </FormLabel>
              <Input type="number" step="any" {...register(`price`)} />
              <FormError>{errors.price?.message}</FormError>
            </FormItem>
          </FormRow>         
          <FormRow>
            <Button $loading={isSubmitting}>{id ? `Update Product` : `Create Product`}</Button>
          </FormRow>
        </Form>
      </LeftContainer>
      <RightContainer>
        <Controller
          control={control}
          name="thumbnail"
          render={({ field }) => (<Dropzone image={field.value} setImage={field.onChange} folder={`products`} />)}/>
        <FormError>{errors.thumbnail?.message}</FormError>
      </RightContainer>
    </>
  )
}

export default ProductAddOrUpdate

const LeftContainer = styled.div`
  ${DefaultCardStyle}
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const RightContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: var(--onSurface);
`
