import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCard,DefaultCardStyle,Form,FormError,FormItem,FormLabel,FormRow,Input,PrimaryButton, 
} from '../styles/CommonStyles'
import Dropzone from '../components/Dropzone'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { doc,setDoc,updateDoc } from 'firebase/firestore'
import { db } from '../services/firebase'
import { toast } from 'react-toastify'
import { useAuth } from '../hooks/useAuth'
import { updateEmail,updatePhoneNumber,updateProfile } from 'firebase/auth'
import { FIREBASE_ERROR_MESSAGES } from '../data/constants'
import PageLayout from '../layouts/PageLayout'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object({ displayName: yup.string().required(`This field is required`).max(80,`This field must be at most 80 characters`) }).required()

export const Profile = () => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(schema) })
  const auth = useAuth()
  const currentUser = useCurrentUser()

  const handleSave = async({ displayName,photoURL }) => {
    const userToUpdate = {
      displayName,
      photoURL,
    }

    try {
      await Promise.all([
        updateProfile(auth.currentUser,userToUpdate),
        // updateEmail(auth.currentUser,email),
        // updatePhoneNumber(auth.currentUser,phoneNumber.toString()),
        updateDoc(doc(db,`users`,currentUser.id),userToUpdate),
      ])
      toast.success(`Profile updated successfully`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  useEffect(() => {
    if(currentUser.id) {
      setValue(`displayName`,currentUser.displayName)
      setValue(`photoURL`,currentUser.photoURL)
    }
  },[currentUser.displayName,currentUser.id,currentUser.photoURL,setValue])

  return (
    <PageLayout title="Profile">
      <GridContainer>
        <LeftContainer>
          <Form onSubmit={handleSubmit(handleSave)}>
            <FormRow>
              <FormItem>
                <FormLabel>
                  Name
                </FormLabel>
                <Input {...register(`displayName`)} />
                <FormError>{errors.displayName?.message}</FormError>
              </FormItem>
            </FormRow>
            {/* <FormRow>
                  <FormItem>
                    <FormLabel>
                      Email
                    </FormLabel>
                    <Input
                      value={email}
                      onChange={setEmail}
                      placeholder="you@example.com"
                      schema={{
                        required: true,
                        type: `text`,
                        format: `email`,
                      }}/>
                  </FormItem>
                  <FormItem>
                    <FormLabel>
                      Phone
                    </FormLabel>
                    <Input
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      placeholder="Phone"
                      schema={{
                        required: true,
                        type: `text`,
                      }}/>
                  </FormItem>
                </FormRow> */}
            {/* <VerticalSpace /> */}
            <FormRow>
              <Button $loading={isSubmitting}>Update Profile</Button>
            </FormRow>
          </Form>
        </LeftContainer>
        <RightContainer>
          <Controller
            control={control}
            name="photoURL"
            render={({ field }) => (<Dropzone image={field.value} setImage={field.onChange} folder={`profile_pics`} />)}/>
        </RightContainer>
      </GridContainer>
    </PageLayout>
  )
}

const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  max-width: 1440px;
  
  @media (min-width: 1380px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
const LeftContainer = styled.div`
  ${DefaultCardStyle}
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`
const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  color: var(--onSurface);
`
