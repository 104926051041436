import React,{ useEffect,useMemo,useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCardStyle,Form,FormError,FormItem,FormLabel,FormRow,Input,PrimaryButton,ResetButton,
} from '../styles/CommonStyles'
import { useNavigate } from 'react-router-dom'
import { useDoc } from '../hooks/useDoc'
import { addDoc,collection,doc,setDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'
import SearchableDropwdown from './SearchableDropdown'
import { useCollection } from '../hooks/useCollection'
import { db } from '../services/firebase'
import { FIREBASE_ERROR_MESSAGES,USER_ROLES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useCurrentUser } from '../hooks/useCurrentUser'

const schema = yup.object({
  selectedCloakroom: yup.object({ value: yup.string().required(`This field is required`) }),
  number: yup.number().required().positive(`Number must be a positive number`).integer(`Number must be an integer`).typeError(`Please enter a valid number`),
}).required()

export const HangerAddOrUpdate = ({ id }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(schema) })
  const [venues] = useCollection(`venues`)
  const [cloakrooms] = useCollection(`cloakrooms`)
  const hanger = useDoc(`hangers/${id}`)
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  const venuesForCloakrooms = useMemo(() => venues.reduce((acc,crr) => ({ ...acc,[crr.id]: crr }),{}),[venues])

  const cloakroomOptions = useMemo(() => cloakrooms.filter(item => !id || id && item.venueId === hanger?.venueId).filter(item => currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === item.venueId)).map(item => ({ value: item.id,label: `${item.number} (${venuesForCloakrooms[item.venueId].name})` })),[cloakrooms,currentUser.role,currentUser.venues,hanger?.venueId,venuesForCloakrooms,id])

  useEffect(() => {
    if(hanger.id) {
      setValue(`selectedCloakroom`,cloakroomOptions.find(item => item.value === hanger.cloakroomId))
      setValue(`number`,hanger.number)
    }
  },[cloakroomOptions,hanger.cloakroomId,hanger.id,hanger.number,setValue])

  const handleSave = async({ selectedCloakroom,number }) => {
    try {
      if(id) {
        await setDoc(doc(db,`hangers`,id),{
          cloakroomId: selectedCloakroom.value,
          number,
        },{ merge: true })
        toast.success(`Hanger updated successfully`)
      } else {
        await addDoc(collection(db,`hangers`),{
          cloakroomId: selectedCloakroom.value,
          venueId: cloakrooms.find(item => item.id === selectedCloakroom.value).venueId,
          number,
        })
        toast.success(`Hanger saved successfully`)
      }

      navigate(`/hangers`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <>
      <LeftContainer>
        <Form onSubmit={handleSubmit(handleSave)}>
          <FormRow>
            <FormItem>
              <FormLabel>
                Cloakroom
              </FormLabel>
              <Controller
                control={control}
                name="selectedCloakroom"
                render={({ field }) => (<SearchableDropwdown
                  options={cloakroomOptions}
                  placeholder="Select Cloakroom"
                  value={field.value}
                  onChange={field.onChange}/>)}/>
              <FormError>{errors.selectedCloakroom?.value?.message}</FormError>
            </FormItem>
            <FormItem>
              <FormLabel>
                Number
              </FormLabel>
              <Input type="number" step="any" {...register(`number`)} />
              <FormError>{errors.number?.message}</FormError>
            </FormItem>
          </FormRow>
          <FormRow>
            <Button $loading={isSubmitting}>{id ? `Update Hanger` : `Create Hanger`}</Button>
          </FormRow>
        </Form>
      </LeftContainer>
      <RightContainer>
      </RightContainer>
    </>
  )
}

const LeftContainer = styled.div`
  ${DefaultCardStyle}
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  color: var(--onSurface);
`
