import React,{ useState } from 'react'
import styled from 'styled-components'
import { DefaultCardStyle,IconButton,ListItem,PrimaryButton,SummaryItem,SummaryList } from '../styles/CommonStyles'
import Table from '../components/Table'
import { dateFormat } from '../utils/format'
import { AiOutlineInfoCircle,AiOutlineShoppingCart } from 'react-icons/ai'
import Modal from '../components/Modal'
import { HANGING_STATUS_ENUM,USER_ROLES } from '../data/constants'
import PageLayout from '../layouts/PageLayout'
import { useCollection } from '../hooks/useCollection'
import { mergeCollections } from '../utils/mergeCollections'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { HangingHistoryCard } from '../components/HangingHistoryCard'
import { useQueryCollection } from '../hooks/useQueryCollection'
import OrderDetailCard from '../components/OrderDetailCard'

const MobileHangingCard = ({
  id,hanger,orders,createdAt,returnedAt,status,handleOpen,handleOrdersOpen,
}) => {
  return (
    <OrderListItem key={id}>
      <SummaryList>
        <SummaryItem>
          <span>Venue</span>
          <div>{hanger?.cloakroom?.venue?.name}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Location</span>
          <div>{hanger?.cloakroom?.number}/{hanger?.number}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Created At</span>
          <div>{dateFormat(createdAt)}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Returned At</span>
          <div>{returnedAt ? dateFormat(returnedAt) : `-`}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Status</span>
          <div>{HANGING_STATUS_ENUM[status]}</div>
        </SummaryItem>
      </SummaryList>
      {orders.length ?
        <ActionContainer>
          <PrimaryButton style={{ flex: 1 }} onClick={() => handleOrdersOpen(id)}><AiOutlineShoppingCart size={18} /></PrimaryButton>
        </ActionContainer> : <></>}
      {/* <Button onClick={() => handleOpen(id)}>Details</Button> */}
    </OrderListItem>
  )
}

export const MyHangings = () => {
  const currentUser = useCurrentUser()
  const [hangings,hangingsLoaded] = useQueryCollection(`hangings`,[[`userId`,`==`,currentUser?.id]])
  const [orders,ordersLoaded] = useQueryCollection(`orders`,[[`hangingId`,`in`,hangings.map(item => item.id)]])
  const [hangers,hangersLoaded] = useCollection(`hangers`)
  const [cloakrooms,cloakroomsLoaded] = useCollection(`cloakrooms`)
  const [venues,venuesLoaded] = useCollection(`venues`)
  const [searchText,setSearchText] = useState()
  const [sortField,setSortField] = useState({ field: `createdAt`,isReverse: true })
  const [selectedHanging,setSelectedHanging] = useState()
  const [showModal,setShowModal] = useState(false)
  const [showOrdersModal,setShowOrdersModal] = useState(false)

  const hangingsWithData = mergeCollections(
    hangings,
    { collection: hangers,field: `id`,on: `hangerId`,name: `hanger`,single: true },
    { collection: cloakrooms,field: `id`,on: `hanger.cloakroomId`,name: `hanger.cloakroom`,single: true },
    { collection: venues,field: `id`,on: `hanger.cloakroom.venueId`,name: `hanger.cloakroom.venue`,single: true },
    { collection: orders,field: `hangingId`,on: `id`,name: `orders` },
  )

  const handleOrdersOpen = id => {
    const hanging = hangingsWithData.find(item => item.id === id)
    setSelectedHanging(hanging)
    setShowOrdersModal(true)
  }

  const handleOpen = id => {
    const hanging = hangingsWithData.find(item => item.id === id)
    setSelectedHanging(hanging)
    setShowModal(true)
  }

  const actionButtons = ({ id,orders }) => {
    return (
      <div className="table-actions">
        {orders.length ? <IconButton $circle onClick={() => handleOrdersOpen(id)}><AiOutlineShoppingCart size={18} /></IconButton> : <></>}
        {/* <IconButton $circle onClick={() => handleOpen(val)} ><AiOutlineInfoCircle size={18} /></IconButton> */}
      </div>
    )
  }

  const columns = [
    { field: `hanger.cloakroom.venue.name`,sortable: true,title: `Venue` },
    {
      field: `location`,
      sortable: true,
      title: `Location`,
      params: params => ({ cloakroomNumber: params.row.hanger.cloakroom.number,hangerNumber: params.row.hanger.number }),
      render: ({ cloakroomNumber,hangerNumber }) => `${cloakroomNumber}/${hangerNumber}`,
      sort: ({ cloakroomNumber,hangerNumber }) => +`${cloakroomNumber}${hangerNumber}`, 
    },
    { field: `createdAt`,sortable: true,title: `Created At`,render: val => dateFormat(val) },
    { field: `returnedAt`,sortable: true,title: `Returned At`,render: val => dateFormat(val) },
    {
      field: `status`,
      sortable: true,
      title: `Status`,
      render: val => HANGING_STATUS_ENUM[val],
      sort: val => HANGING_STATUS_ENUM[val],
    },
    { field: `action`,css: { width: `80px`,position: `flex-end` },params: params => params.row,render: actionButtons },
  ]

  return (
    <PageLayout title="Stashed" search={[searchText,setSearchText]} sort={[sortField,setSortField,columns]}>
      <OrderList>
        <Table
          loading={!hangingsLoaded || !ordersLoaded || !hangersLoaded || !cloakroomsLoaded || !venuesLoaded}
          columns={columns}
          rows={hangingsWithData}
          searchText={searchText}
          sortField={sortField}
          MobileCard={MobileHangingCard}
          mobileCardFunctions={{ handleOpen,handleOrdersOpen }} />
      </OrderList>
      {selectedHanging ? (
        <Modal scroll title="Stashed Histroy" showModal={showModal} setShowModal={setShowModal}>
          <HangingHistoryCard {...selectedHanging} userRole={USER_ROLES.ADMIN}/> 
        </Modal>
      ) : <></>}
      {selectedHanging ? (
        <Modal scroll title={`Orders`} showModal={showOrdersModal} setShowModal={setShowOrdersModal}>
          {selectedHanging.orders.map(order => {
            return <ModalOrderDetailCard key={order.id} {...order} />
          })}
        </Modal>
      ) : <></>}
    </PageLayout>
  )
}

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`
const OrderListItem = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`
const ModalOrderDetailCard = styled(OrderDetailCard)`
  padding: 16px;
  background-color: #111;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`
