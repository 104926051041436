import { useMemo } from 'react'
import { useCart } from './useCart'

export const useHandleCart = id => {
  const [cartItems,setCartItems] = useCart()

  const cartProduct = useMemo(() => cartItems.find(item => item.id === id),[cartItems,id])

  const handleAddToCart = () => {
    if (cartProduct) 
      setCartItems([...cartItems.map(item => item.id === id ? { ...item,count: item.count + 1 } : item)])
    else 
      setCartItems([...cartItems,{ id,count: 1 }])
  }

  const handleRemoveFromCart = () => {
    if(cartProduct?.count === 1)
      setCartItems([...cartItems.filter(item => item.id !== id)])
    else
      setCartItems([...cartItems.map(item => item.id === id ? { ...item,count: item.count - 1 } : item)])
  }

  return { cartProduct,handleAddToCart,handleRemoveFromCart }
}
