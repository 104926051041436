import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'
import { HangingAddOrUpdate } from '../components/HangingAddOrUpdate'

export const EditHanging = () => {
  const { id } = useParams()

  return (
    <PageLayout title="Edit Stashed">
      <PageContainerDivided>
        <HangingAddOrUpdate id={id} />
      </PageContainerDivided>
    </PageLayout>
  )
}
