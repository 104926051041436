import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { useCurrentUser } from '../hooks/useCurrentUser'

export const GuestGuard = ({ children }) => {
  const currentUser = useCurrentUser()
  const auth = useAuth()

  if(auth && auth?.currentUser && currentUser.id) 
    return <Navigate to={`/`} />

  // TODO: add logic of initialization so user doesnt see flashing screen
  // if (!isInitialized) 
  //   return <LoadingScreen />

  return (
    <>
      {children}
    </>
  )
}
