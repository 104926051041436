export const API_URL = process.env.REACT_APP_API_URL

export const WEBAPP_URL = process.env.REACT_APP_WEPAPP_URL

export const VAPID_KEY = process.env.REACT_APP_VAPID_KEY

export const FIREBASE_ERROR_MESSAGES = {
  "auth/user-not-found": `Email or password is wrong!`,
  "auth/wrong-password": `Email or password is wrong!`,
  "auth/email-already-in-use": `This account already exists!`,
}

export const ORDER_STATUS_ENUM = {
  0: `Ordered`,
  1: `Approved`,
  2: `Ready`,
  3: `Delivered`,
}

export const ORDER_STATUS = {
  ORDERED: 0,
  APPROVED: 1,
  READY: 2,
  DELIVERED: 3,
}

export const HANGING_STATUS_ENUM = {
  0: `Created`,
  1: `Out`,
  2: `Returned`,
}

export const HANGING_STATUS = {
  CREATED: 0,
  OUT: 1,
  RETURNED: 2,
}

export const USER_PERMISSONS = {
  ADMIN: [`profile`,`orders`,`products`,`inventory`,`venues`,`cloakrooms`,`users`,`hangers`,`hangings`],
  CLOAKROOM: [`profile`,`orders`,`inventory`,`hangers`,`hangings`],
  USER: [`profile`,`my-orders`,`stashed`],
}

export const USER_ROLES = {
  ADMIN: `admin`,
  CLOAKROOM: `cloakroom`,
  USER: `user`,
}

export const CURRENCIES = {
  GBP: `gbp`,
  USD: `usd`,
}

export const VAT_RATE = 0.2

export const AUTH_ACTION_MODES = { RESET_PASSWORD: `resetPassword` }
