import React,{ useRef,useState } from 'react'
import styled from 'styled-components'
import { ResetButton,ResetInput } from '../styles/CommonStyles'
import { AiOutlineFilter,AiFillCaretDown,AiOutlineSortAscending } from 'react-icons/ai'
import { BsSortDownAlt } from 'react-icons/bs'
import useDropdownVisible from '../hooks/useDropdownVisible'

const SortDropdown = ({ sortField,setSortField,fields }) => {
  const { ref,isDropdownVisible,setIsDropdownVisible } = useDropdownVisible(false)

  const handleToggle = () => {
    setIsDropdownVisible(!isDropdownVisible)
  }

  const handleFieldClick = (field,isSelected) => {
    setSortField({ field,isReverse: isSelected ? !sortField.isReverse : false })
  }

  return (
    <StyledDropdown className={isDropdownVisible ? `open` : ``} ref={ref}>
      <OpenDropdown onClick={handleToggle}>
        <OpenDropdownIcon />
      </OpenDropdown>
      <DropdownMenu isOpen={isDropdownVisible}>
        {fields.filter(item => item.sortable).map(item => {
          const isSelected = item.field === sortField?.field
          return (
            <DropdownMenuItem key={item.field} className={isSelected ? `selected` : ``} onClick={() => handleFieldClick(item.field,isSelected)}>
              {item.title}
              <Arrow $isReverse={sortField?.isReverse} $isSelected={isSelected} />
            </DropdownMenuItem>
          )
        })}
      </DropdownMenu>
    </StyledDropdown>
  )
}

export default SortDropdown

const StyledDropdown = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: all .2s ease-in-out;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  font-size: 24px;

  &.open {
    color: var(--onBackground);
    background-color: var(--background);
    font-size: 20px;
  }
`
const OpenDropdown = styled.button`
  ${ResetButton}
  color: inherit;
  font-size: inherit;
`
const OpenDropdownIcon = styled(BsSortDownAlt)`
  color: inherit;
`
const DropdownMenu = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  display: ${p => p.isOpen ? `flex` : `none`};
  flex-direction: column;
  background-color: var(--background);
  border-radius: 4px;
  z-index: 10;
`
const DropdownMenuItem = styled.button`
  ${ResetButton}
  gap: 8px;
  justify-content: space-between;
  padding: 8px 12px;
  width: auto;
  white-space: nowrap;
  color: var(--onBackground);
  font-size: 14px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dividerOnBackground);
  }

  &.selected {
    color: var(--light);
  }
`
const Arrow = styled(AiFillCaretDown)`
  opacity: 0;
  ${p => p.$isReverse && `
    transform: rotate(180deg);
  `}
  ${p => p.$isSelected && `
    opacity: 1;
  `}
`
