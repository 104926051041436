import _ from 'lodash'
import { getDepthValue } from './getDepthValue'

export const mergeCollections = (main,...subs) => {
  const mainWithSubs = main.map(item => {
    const mainDoc = _.cloneDeep(item)
    subs.map(sub => {
      const subDocs = sub.single ? sub.collection.find(ele => ele[sub.field] === getDepthValue(mainDoc,sub.on)) : sub.collection.filter(ele => ele[sub.field] === getDepthValue(mainDoc,sub.on))
      _.set(mainDoc,sub.name,subDocs)
    })
    return mainDoc
  })
  return mainWithSubs
}
