import { useNavigate,useSearchParams } from 'react-router-dom'
import { useCurrentHanging } from './useCurrentHanging'
import { useEffect } from 'react'
import { doc,getDoc,setDoc } from 'firebase/firestore'
import { db,parseDocSnapshot } from '../services/firebase'
import { toast } from 'react-toastify'
import { HANGING_STATUS } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useCurrentUser } from './useCurrentUser'

export const useScanHanging = () => {
  const currentUser = useCurrentUser()
  const [currentHanging,setCurrentHanging] = useCurrentHanging()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const hangingId = searchParams.get(`hanging`)

  useEffect(() => {
    (async() => {
      if(!hangingId || hangingId === currentHanging.hangingId || !currentUser.isInitialized) return

      try {
        const hangingRef = doc(db,`hangings`,hangingId)
        const hanging = parseDocSnapshot(await getDoc(hangingRef))
        if(!hanging.id)
          return toast.error(`QR code not found!`)

        if(hanging.status === HANGING_STATUS.OUT && hanging.userId !== currentUser.id)
          return toast.error(`Sorry this QR code is already in use!`)

        if(hanging.status === HANGING_STATUS.RETURNED)
          return toast.error(`Sorry this QR code is already returned!`)

        const hanger = parseDocSnapshot(await getDoc(doc(db,`hangers`,hanging.hangerId)))
        if(!hanger.id)
          return toast.error(`Hanger not found!`)

        const cloakroom = parseDocSnapshot(await getDoc(doc(db,`cloakrooms`,hanger.cloakroomId)))
        if(!cloakroom.id)
          return toast.error(`Cloakroom not found!`)

        const hangingToSave = {
          status: HANGING_STATUS.OUT,
          scannedAt: new Date(),
        }

        await setDoc(hangingRef,hangingToSave,{ merge: true })
        setCurrentHanging({ hangingId,venueId: cloakroom.venueId })
        navigate(`/`)
      } catch (error) {
        handleFirebaseErrors(error) 
      }
    })()
  },[currentHanging.hangingId,hangingId,navigate,setCurrentHanging,currentUser.id,currentUser.isInitialized])
}
