import styled from 'styled-components'
import { ProductItem } from '../components/ProductItem'
import { Container,EmptyStateContainer,EmptyStateText,List,PrimaryButton } from '../styles/CommonStyles'
import { useCollection } from '../hooks/useCollection'
import { sortBy } from '../utils/sortBy'
import { mergeCollections } from '../utils/mergeCollections'
import { BsShopWindow } from 'react-icons/bs'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useQueryCollection } from '../hooks/useQueryCollection'
import { useCurrentHanging } from '../hooks/useCurrentHanging'
import { useScanHanging } from '../hooks/useScanHanging'
import { ScanHanger } from '../components/ScanHanger'
import { SummaryListLoading } from '../components/SummaryListLoading'
import { HomeHowTo } from '../components/HomeHowTo'

export const Home = () => {
  const [products,productsLoaded] = useCollection(`products`)
  const currentUser = useCurrentUser()
  const [currentHanging] = useCurrentHanging()
  const [inventory,inventoryLoaded] = useQueryCollection(`inventory`,[[`venueId`,`==`,currentHanging.venueId],[`quantity`,`>`,0]])

  useScanHanging()

  const productsWithInventory = mergeCollections(products,{ collection: inventory,field: `productId`,on: `id`,name: `inventory` })
  const sorted = [...productsWithInventory].sort(sortBy(`title`,false))

  if(currentHanging.hangingId && !productsLoaded || !inventoryLoaded) return (
    <Container>
      <ProductList>
        {[...Array(6)].map((item,index) => <SummaryListLoading key={index} type="home" />)}
      </ProductList>
    </Container>
  )

  return (
    <Container>
      <ProductList>
        {!currentHanging.hangingId ? (
          <HomeHowTo />
        ) : (
          <>
            {/* {((currentUser && currentUser.role === USER_ROLES.USER) || !currentUser.id) && sorted.length ? (sorted.map(item => { */}
            {sorted.length && inventory.length ? (sorted.map(item => {
              return <ProductItem key={item.id} {...item} />
            }))
              : 
              (<EmptyStateContainer>
                <BsShopWindow />
                <EmptyStateText>There are no available products for now!</EmptyStateText>
              </EmptyStateContainer>)
            }
          </>
        )}
        <ScanHanger />
      </ProductList>
    </Container>
  )
}

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const ProductList = styled.div`
  ${List}
  --gap: 32px;
`
