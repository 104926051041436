import React,{ useEffect,useMemo,useState } from 'react'
import styled from 'styled-components'
import { priceFormat } from '../utils/format'
import { useCart } from '../hooks/useCart'
import { Link,useNavigate } from 'react-router-dom'
import { DefaultCardStyle } from '../styles/CommonStyles'
import { useCollection } from '../hooks/useCollection'
import { API_URL,VAT_RATE } from '../data/constants'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { toast } from 'react-toastify'
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'
import { ConfirmDialog } from './ConfirmDialog'

const CartSummary = ({ showCheckoutButton }) => {
  const navigate = useNavigate()
  const [products] = useCollection(`products`)
  const currentUser = useCurrentUser()
  const [paymentMethod,setPaymentMethod] = useState()
  const [cartItems] = useCart()

  const bagTotal = useMemo(() => cartItems.reduce((acc,crr) => acc + ((products.find(productItem => productItem.id === crr.id)?.price) * crr.count),0),[cartItems,products]) || 0
  const vat = bagTotal * VAT_RATE
  const total = bagTotal + vat

  useEffect(() => {
    (async() => {
      if (!currentUser.isInitialized || !currentUser.stripePaymentMethod) return

      try {
        const res = await axios.get(`${API_URL}/get-payment-method-detail/${currentUser.stripePaymentMethod}`)
        setPaymentMethod(res.data)
      } catch (error) {
        toast.error(error?.response?.data?.error || error.message)
      }
    })()
  },[currentUser.isInitialized,currentUser.stripePaymentMethod,currentUser.id])

  const handlePay = amount => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDialog title="Are you sure?" message={`We will charge you ${amount}?`} confirmText="Pay now!" onClose={onClose} onConfirm={() => navigate(`/checkout`)} />
      ),
    })
  }

  return (
    <StyledCartSummary>
      <CartSummaryTitle>Pricing Details</CartSummaryTitle>
      <CartSummaryItemContainer>
        <CartSummaryItem>
          <CartSummaryItemTitle>Bag Total</CartSummaryItemTitle>
          <CartSummaryItemText>{priceFormat(bagTotal)}</CartSummaryItemText>
        </CartSummaryItem>
        {/* <CartSummaryItem>
        <CartSummaryItemTitle>Bag Discount</CartSummaryItemTitle>
        <CartSummaryItemText>{discount}</CartSummaryItemText>
      </CartSummaryItem> */}
        <CartSummaryItem>
          <CartSummaryItemTitle>VAT</CartSummaryItemTitle>
          <CartSummaryItemText>{priceFormat(vat)}</CartSummaryItemText>
        </CartSummaryItem>
        <CartSummaryItem total>
          <CartSummaryItemTitle>Total</CartSummaryItemTitle>
          <CartSummaryItemText>{priceFormat(total)}</CartSummaryItemText>
        </CartSummaryItem>
      </CartSummaryItemContainer>
      {showCheckoutButton && paymentMethod ?
        <PayNow>
          <CartInfo>
            <div>**** **** **** {paymentMethod.last4}</div>
            <div>{paymentMethod.month}/{paymentMethod.year}</div>
          </CartInfo>
          <CheckoutButton onClick={() => handlePay(priceFormat(total))} $isPayment>Pay now!</CheckoutButton>
        </PayNow>
        : showCheckoutButton ? <CheckoutButton to={currentUser.isInitialized && currentUser.id ? `/checkout` : `/login?redirect=/checkout`}>Checkout</CheckoutButton>
          : <></>
      }
    </StyledCartSummary>
  )
}

export default CartSummary

const StyledCartSummary = styled.div`
  ${DefaultCardStyle}
  display: flex;
  flex-direction: column;
  width: 100%;
`
const CartSummaryTitle = styled.h4`
  margin-bottom: 8px;
  font-size: 18px;
`
const CartSummaryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const CartSummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  margin-top: ${({ total }) => total ? `8px` : `0`};

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
`
const CartSummaryItemTitle = styled.span`
`
const CartSummaryItemText = styled.span`
`
const CheckoutButton = styled(Link)`
  width: 100%;
  margin-top: 8px;
  padding: 12px;
  font-size: 14px;
  color: var(--onPrimaryButton);
  background-color: var(--primaryButton);
  border: 0;
  border-radius: 4px;
  cursor: pointer;

  ${p => p.$isPayment && `
    background-color: var(--success);
    color: var(--onSuccess);
  `}
`
const PayNow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const CartInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid var(--dividerOnSurface);
  border-radius: 4px;
`
