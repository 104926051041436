import { atom,useAtom } from 'jotai'
import { atomWithStorage,createJSONStorage } from 'jotai/utils'
import { SimpleCacheStorage } from './SimpleCacheStorage'
import { SimpleCookieStorage } from './SimpleCookieStorage'

// export const atomWithLocalStorage = async(key,initialValue) => {
//   const getInitialValue = async() => {
//     const item = await getLocalStorageToCache(key)
//     console.log(item)
//     // const item = localStorage.getItem(key)
//     if (item !== null)
//       return JSON.parse(item)
//     return initialValue
//   }

//   const baseAtom = atom(async() => await getInitialValue())

//   return atom(
//     async get => get(baseAtom),
//     async(get,set,update) => {
//       const nextValue =
//         typeof update === `function` ? update(await get(baseAtom)) : update
//       set(baseAtom,nextValue)
//       localStorage.setItem(key,JSON.stringify(nextValue))
//     },
//   )
// }

// const saveToCache = async(key,value) => {
//   try {
//     const cache = await caches.open(`storage`)
//     const responseBody = JSON.stringify(value)
//     const response = new Response(responseBody)
//     await cache.put(`/cs-${key}`,response)
//     console.log(`Storage saved to cache! 🎉`)
//   } catch (error) {
//     console.log(`saveToCache error:`,{ error })
//   }
// }

// const getFromCache = async key => {
//   try {
//     const cache = await caches.open(`storage`)
//     const response = await cache.match(`/cs-${key}`)

//     if (!response) return null

//     return response

//     // const responseBody = await response.json()
//     // return responseBody[`storage`]
//   } catch (error) {
//     console.log(`getFromCache error:`,{ error })
//   }
// }

// window.simpleCacheStorage = new SimpleCacheStorage()
// const cacheStorage = createJSONStorage(() => simpleCacheStorage)
// export const atomWithLocalStorage = (key,initialValue) => atomWithStorage(key,initialValue,cacheStorage)

export const atomWithLocalStorage = atomWithStorage

// const storage = createJSONStorage(() => sessionStorage)
// export const atomWithLocalStorage = (key,initialValue) => atomWithStorage(key,initialValue)

// export const atomWithLocalStorage = (key,initialValue) => {
//   const getInitialValue = () => {
//     const item = localStorage.getItem(key)
//     if (item !== null)
//       return JSON.parse(item)
//     return initialValue
//   }

//   const baseAtom = atom(getInitialValue())

//   return atom(
//     get => get(baseAtom),
//     (get,set,update) => {
//       const nextValue =
//         typeof update === `function` ? update(get(baseAtom)) : update
//       set(baseAtom,nextValue)
//       localStorage.setItem(key,JSON.stringify(nextValue))
//     },
//   )
// }
