import React,{ Fragment } from 'react'
import styled from 'styled-components'
import { Link,useLocation,useNavigate } from 'react-router-dom'
import UserGreeting from './UserGreeting'
import { USER_PERMISSONS } from '../data/constants'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { sidebarLinks } from '../routes/sidebarLinks'
import { CustomContentLoader } from './CustomContentLoader'

export const Sidebar = () => {
  const currentUser = useCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <SidebarContainer>
      <SidebarWrapper>
        <SidebarUserGreeting />
        <SidebarMenu>
          <SidebarTitle></SidebarTitle>
          <SidebarList>
            {currentUser.isInitialized ? sidebarLinks.items.map((item,index) => {
              const { type,showOnMobile,route,icon,text } = item

              if(type === `protected` && !(currentUser.id && USER_PERMISSONS[currentUser.role?.toUpperCase()]?.includes(route.split(`/`)[1])))
                return <Fragment key={index}></Fragment>

              if(type === `auth` && !((!currentUser.id && route === `/login`) || (currentUser.id && route === `/logout`)))
                return <Fragment key={index}></Fragment>

              return (
                <SidebarItem key={index} $showOnMobile={showOnMobile} className={location.pathname === route || (route !== `/` && location.pathname.startsWith(route)) ? `clicked` : ``} onClick={() => navigate(route)}>
                  {icon}
                  {text}
                </SidebarItem>
              )
            }) : <>
              <SidebarItem>
                <CustomContentLoader width="100%">
                  <rect x="0" y="0" rx="4" ry="4" width="100%" height="16" />
                  <rect x="0" y="44" rx="4" ry="4" width="100%" height="16" />
                </CustomContentLoader>
              </SidebarItem>
            </>}
          </SidebarList>
          <BottomList>
            {sidebarLinks.settings.map((item,index) => (
              <BottomItem to={item.route} key={index}>
                {item.icon}
                {item.text}
              </BottomItem>
            ))}
          </BottomList>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

const SidebarContainer = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 990;
  height: 60px;
  width: 100%;
  background: var(--background);

  @media screen and (min-width: 576px) {
    position: fixed;
    top: 50px;
    left: 0;
    display: flex;
    height: calc(100vh - 50px);
    width: 100%;
    max-width: 190px;
  }

  @media screen and (min-width: 992px) {
    max-width: 240px;
  }
`

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
`
const SidebarUserGreeting = styled(UserGreeting)`
  display: none;

  @media screen and (min-width: 576px) {
    display: flex;
  }
`
const SidebarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 10px;

  @media screen and (min-width: 576px) {
    padding: 24px 24px 0 24px;
  }
`

const SidebarTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`

const SidebarList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 575.98px) {
    display: flex;
    justify-content: space-around;
    gap: 12px;
    align-items: center;
  }
`

const SidebarItem = styled.li`
  display: ${p => p.$showOnMobile ? `flex` : `none`};
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    color: var(--light);
  }

  &.clicked {
    color: var(--light);
  }

  @media screen and (min-width: 576px) {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 2em;
  }
`
const BottomList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: auto;
  
  @media screen and (max-width: 575.98px) {
    display: none;
  }
  `
const BottomItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #B8B8B8;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  &:hover {
    color: var(--light);
  }
`
