import styled from 'styled-components'
import { DefaultCardStyle,PrimaryButton,PrimaryButtonDestructive } from '../styles/CommonStyles'
import { useState } from 'react'

export const ConfirmDialog = ({ title,message,confirmText,cancelText = `Cancel`,onClose,onConfirm = () => {} }) => {
  const [loading,setLoading] = useState(false)

  return (
    <StyledConfirmDialog>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <ActionArea>
        <PrimaryButtonDestructive
          $loading={loading}
          onClick={() => {
            setLoading(true)
            onConfirm()
            onClose()
            setLoading(false)
          }}>
          {confirmText}
        </PrimaryButtonDestructive>
        <PrimaryButton onClick={onClose}>{cancelText}</PrimaryButton>
      </ActionArea>
    </StyledConfirmDialog>
  )
}

const StyledConfirmDialog = styled.div`
  ${DefaultCardStyle}
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--dividerOnSurface);
`
const Title = styled.h1`
  font-size: 24px;
`
const Message = styled.p`
`
const ActionArea = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
`
