export const ROUTES = {
  INDEX: `/`,
  LOGIN: `/login`,
  REGISTER: `/register`,
  LOGOUT: `/logout`,
  ABOUT: `/about`,
  LEGAL: `/legal`,
  PRIVACY: `/privacy`,
  CONTACT: `/contact`,
  PROFILE: `/profile`,
  ORDERS: `/orders`,
  MY_ORDERS: `/my-orders`,
  HANGING: `/hangings`,
  MY_HANGINGS: `/my-hangings`,
  CART: `/cart`,
  CHECKOUT: `/checkout`,
  PAYMENT_RESULT: `/payment-result`,
  PRODUCTS: {
    INDEX: `/products`,
    NEW: `/products/new`,
    EDIT: `/products/:id/edit`,
  },
  INVENTORY: {
    INDEX: `/inventory`,
    NEW: `/inventory/new`,
    EDIT: `/inventory/:id/edit`,
  },
  HANGERS: {
    INDEX: `/hangers`,
    NEW: `/hangers/new`,
    EDIT: `/hangers/:id/edit`,
  },
  VENUES: {
    INDEX: `/venues`,
    NEW: `/venues/new`,
    EDIT: `/venues/:id/edit`,
  },
  USERS: { INDEX: `/users` },
}
