import { Link,useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import {
  Form,PrimaryButton,FormRow,FormLabel,FormError,FormItem,Input,
} from '../styles/CommonStyles'
import { getAuth,sendPasswordResetEmail } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'

const schema = yup.object({ email: yup.string().email(`PLease enter a valid email`).required(`This field is required`) }).required()

export const ForgotPassword = () => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(schema) })
  const auth = getAuth()
  const navigate = useNavigate()

  const handleResetPassword = async({ email }) => {
    try {
      await sendPasswordResetEmail(auth,email)
      toast.success(`Reset email has been sent!`)
      navigate(`/login`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <Container>
      <CompanyName onClick={() => navigate(`/`)}>
        Stash AI
      </CompanyName>
      <Title>Reset Password</Title>
      <Card>
        <Form onSubmit={handleSubmit(handleResetPassword)}>
          <FormRow>
            <FormItem full>
              <Input placeholder="you@example.com" {...register(`email`)} />
              <FormError>{errors.email?.message}</FormError>
            </FormItem>
          </FormRow>
          <PrimaryButton $loading={isSubmitting}>
            Reset Password
          </PrimaryButton>
        </Form>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 100vh;

  p{
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
`
const Card = styled.div`
  background-color: var(--darkest);
  width: 100%;
  max-width: 350px;
  border-radius: 1em;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const CompanyName = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`
const Title = styled.div`
  font-size: 28px;
  color: var(--onBackground);
  width: 100%;
  max-width: 350px;
  padding: 16px 30px;
`
