import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator,getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { firebaseConfig } from '../config'
import { getMessaging,getToken,isSupported,onMessage } from 'firebase/messaging'
import { VAPID_KEY } from '../data/constants'

export const app = initializeApp(firebaseConfig)

export const messaging = getMessaging(app)

export const storage = getStorage(app)

export const db = getFirestore(app)
if (process.env.REACT_APP_USE_FIREBASE_EMULATOR)
  connectFirestoreEmulator(db,`127.0.0.1`,8080)
export const { FieldValue } = db

export const getImageUrlFromFullPath = fullPath => `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.projectId}.appspot.com/o/${encodeURIComponent(fullPath)}?alt=media`

export const parseDocSnapshot = snapshot => {
  if(!snapshot.exists()) return {}
  const { ref,...output } = { ...snapshot.data() }
  const recursivelyConvertTimestamps = thing => {
    if(!thing) return thing
    if(thing.seconds) return thing.seconds * 1000
    if(Array.isArray(thing)) return thing.map(t => recursivelyConvertTimestamps(t))
    if(isObject(thing)) Object.keys(thing).forEach(key => thing[key] = recursivelyConvertTimestamps(thing[key]))
    return thing
  }
  return {
    ...recursivelyConvertTimestamps(output),
    id: snapshot.id,
    path: snapshot.ref.path,
  }
}

export const parseCollectionSnapshot = snapshot => {
  const output = []
  snapshot.forEach(item => {
    output.push(parseDocSnapshot(item))
  })
  return output
}

export const isObject = thing => 
  !(thing instanceof Array) 
  && thing instanceof Object 
  && typeof thing !== `function`
  && thing

export const getPushToken = async() => {  
  try {
    const token = await getToken(messaging,{ vapidKey: VAPID_KEY })
    return token
  } catch (error) {
    console.log(`---error---`,error)
  }
}

export const onMessageListener = handleMessage =>
  onMessage(messaging,payload => {
    handleMessage(payload)
  })
  
