import styled from 'styled-components'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'
import { HangingAddOrUpdate } from '../components/HangingAddOrUpdate'

export const NewHanging = () => {
  return (
    <PageLayout title="New Stashed">
      <PageContainerDivided>
        <HangingAddOrUpdate />
      </PageContainerDivided>
    </PageLayout>
  )
}
