import { AiOutlineHome,AiOutlineShopping,AiOutlineUser } from 'react-icons/ai'
import { BsBoxes,BsBuildings,BsDoorClosed,BsListCheck } from 'react-icons/bs'
import { FiLogIn,FiLogOut,FiUsers } from 'react-icons/fi'
import { GiHanger } from 'react-icons/gi'
import { IoBriefcaseOutline } from 'react-icons/io5'
import { MdOutlinePrivacyTip } from 'react-icons/md'
import { TbHanger } from 'react-icons/tb'

export const sidebarLinks = {
  items: [
    { type: `all`,icon: <AiOutlineHome />,text: `Home`,route: `/`,showOnMobile: true },
    { type: `protected`,icon: <AiOutlineUser />,text: `Profile`,route: `/profile`,showOnMobile: true },
    { type: `protected`,icon: <AiOutlineShopping />,text: `Orders`,route: `/orders`,showOnMobile: true },
    { type: `protected`,icon: <AiOutlineShopping />,text: `My Orders`,route: `/my-orders`,showOnMobile: true },
    { type: `protected`,icon: <BsBoxes />,text: `Products`,route: `/products`,showOnMobile: true },
    { type: `protected`,icon: <BsListCheck />,text: `Inventory`,route: `/inventory`,showOnMobile: true },
    { type: `protected`,icon: <BsBuildings />,text: `Venues`,route: `/venues` },
    { type: `protected`,icon: <BsDoorClosed />,text: `Cloakrooms`,route: `/cloakrooms` },
    { type: `protected`,icon: <TbHanger />,text: `Hangers`,route: `/hangers` },
    { type: `protected`,icon: <GiHanger />,text: `Stashed`,route: `/hangings` },
    { type: `protected`,icon: <GiHanger />,text: `Stashed`,route: `/stashed`,showOnMobile: true },
    { type: `protected`,icon: <FiUsers />,text: `Users`,route: `/users` },
    { type: `auth`,icon: <FiLogIn />,text: `Login`,route: `/login`,showOnMobile: true },
    { type: `auth`,icon: <FiLogOut/>,text: `Logout`,route: `/logout` },
  ],
  settings: [
    { icon: <MdOutlinePrivacyTip />,text: `Privacy Policy`,route: `/privacy` },
    { icon: <IoBriefcaseOutline />,text: `Legal`,route: `/legal` },
  ],
}
