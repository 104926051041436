import { atom,useAtom } from 'jotai'
import _ from 'lodash'
import { collection,onSnapshot,query,where } from 'firebase/firestore'
import { db,parseCollectionSnapshot } from '../services/firebase'
import { useState } from 'react'

export const collectionsAtom = atom({})

export const colSubscriptions = {}

export const colSubscriptionsLoaded = {}

export const useQueryCollection = (name,queries,orQueries) => {
  const queryToUse = orQueries || queries
  const [collections,setCollections] = useAtom(collectionsAtom)
  const queryKey = name + JSON.stringify(queryToUse)
  if(!name || queryToUse.some(item => item.some(ele => ele === undefined || (typeof ele === `object` && !ele.length)))) return [[],true]

  if(colSubscriptions[queryKey]) 
    return [collections[queryKey] || [],colSubscriptionsLoaded[queryKey]]

  const q = query(collection(db,name),...queryToUse.map(item => where(...item)))

  colSubscriptions[queryKey] = onSnapshot(q,snapshot => {
    console.log(`---GOT COLLECTION SNAPSHOT ${name}---`)
    setCollections(s => {
      const output = _.cloneDeep(s)
      output[queryKey] = parseCollectionSnapshot(snapshot)
      colSubscriptionsLoaded[queryKey] = true
      return output
    })
  })
  return [collections[queryKey] || [],colSubscriptionsLoaded[queryKey]]
}
