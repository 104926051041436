import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { DefaultCardStyle,IconButton,ListItem,PrimaryButton,SummaryItem,SummaryList } from '../styles/CommonStyles'
import Table from '../components/Table'
import { dateFormat,priceFormat } from '../utils/format'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import Modal from '../components/Modal'
import OrderDetailCard from '../components/OrderDetailCard'
import { ORDER_STATUS_ENUM,USER_ROLES } from '../data/constants'
import PageLayout from '../layouts/PageLayout'
import SearchableDropwdown from '../components/SearchableDropdown'
import { useCollection } from '../hooks/useCollection'
import { mergeCollections } from '../utils/mergeCollections'
import { doc,setDoc } from 'firebase/firestore'
import { db } from '../services/firebase'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useQueryCollection } from '../hooks/useQueryCollection'

const MobileOrderCard = ({
  id,customer,hanging,date,total,items,status,handleOpen,handleStatusChange,
}) => {
  return (
    <OrderListItem>
      <SummaryList>
        <SummaryItem>
          <span>Customer</span>
          <div>{customer?.displayName}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Date</span>
          <div>{dateFormat(date)}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Total</span>
          <div>{priceFormat(total)}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Items</span>
          <div>{items.reduce((acc,crr) => acc + crr.count,0)}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Venue</span>
          <div>{hanging?.hanger?.cloakroom?.venue?.name}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Location</span>
          <div>{hanging?.hanger?.cloakroom?.number}/{hanging?.hanger?.number}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Status</span>
          <div><StatusChangeSelect id={id} val={status} handleStatusChange={handleStatusChange} /></div>
        </SummaryItem>
      </SummaryList>
      <Button onClick={() => handleOpen(id)}>Details</Button>
    </OrderListItem>
  )
}

const StatusChangeSelect = ({ id,val,handleStatusChange }) => {
  const options = []

  for (const [key,value] of Object.entries(ORDER_STATUS_ENUM)) 
    options.push({ value: +key,label: value })

  return (
    <SearchableDropwdown
      options={options}
      placeholder="Select Status"
      value={options.find(item => item.value === val)}
      onChange={e => handleStatusChange(id,e)}/>
  )
}

const Orders = () => {
  const currentUser = useCurrentUser()
  const [orders,ordersLoaded] = useQueryCollection(`orders`,[[`venueId`,`in`,currentUser.venues]],currentUser.role === USER_ROLES.ADMIN && [])
  const [users,usersLoaded] = useCollection(`users`)
  const [hangings,hangingsLoaded] = useQueryCollection(`hangings`,[[`venueId`,`in`,currentUser.venues]],currentUser.role === USER_ROLES.ADMIN && [])
  const [hangers,hangersLoaded] = useCollection(`hangers`)
  const [cloakrooms,cloakroomsLoaded] = useCollection(`cloakrooms`)
  const [venues,venuesLoaded] = useCollection(`venues`)
  const [searchText,setSearchText] = useState()
  const [sortField,setSortField] = useState({ field: `date`,isReverse: true })
  const [selectedOrder,setSelectedOrder] = useState()
  const [showModal,setShowModal] = useState(false)

  const ordersWithCustomers = mergeCollections(
    orders,
    { collection: hangings,field: `id`,on: `hangingId`,name: `hanging`,single: true },
    { collection: users,field: `id`,on: `hanging.userId`,name: `customer`,single: true },
    { collection: hangers,field: `id`,on: `hanging.hangerId`,name: `hanging.hanger`,single: true },
    { collection: cloakrooms,field: `id`,on: `hanging.hanger.cloakroomId`,name: `hanging.hanger.cloakroom`,single: true },
    { collection: venues,field: `id`,on: `hanging.hanger.cloakroom.venueId`,name: `hanging.hanger.cloakroom.venue`,single: true },
  )

  const handleOpen = id => {
    const order = ordersWithCustomers.find(item => item.id === id)
    setSelectedOrder(order)
    setShowModal(true)
  }

  const handleStatusChange = async(id,selectedStatus) => {
    try {
      await setDoc(doc(db,`orders`,id),{ status: selectedStatus.value },{ merge: true })
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  const actionButtons = val => {
    return (
      <div className="table-actions">
        <IconButton $circle onClick={() => handleOpen(val)} ><AiOutlineInfoCircle size={18} /></IconButton>
      </div>
    )
  }

  const columns = [
    { field: `customer.displayName`,sortable: true,title: `Customer` },
    { field: `date`,sortable: true,title: `Date`,render: val => dateFormat(val) },
    { field: `total`,sortable: true,title: `Total`,render: val => priceFormat(val) },
    { field: `items`,sortable: true,title: `Items`,render: val => val.reduce((acc,crr) => acc + crr.count,0) },
    { field: `hanging.hanger.cloakroom.venue.name`,sortable: true,title: `Venue` },
    {
      field: `location`,
      sortable: true,
      title: `Location`,
      params: params => ({ cloakroomNumber: params.row.hanging.hanger.cloakroom.number,hangerNumber: params.row.hanging.hanger.number }),
      render: ({ cloakroomNumber,hangerNumber }) => `${cloakroomNumber}/${hangerNumber}`,
      sort: ({ cloakroomNumber,hangerNumber }) => +`${cloakroomNumber}${hangerNumber}`, 
    },
    {
      field: `status`,
      sortable: true,
      title: `Status`,
      css: { overflow: `unset` },
      params: params => ({ id: params.row.id,val: params.row.status }),
      render: ({ id,val }) => <StatusChangeSelect id={id} val={val} handleStatusChange={handleStatusChange} />,
      sort: ({ val }) => ORDER_STATUS_ENUM[val],
    },
    { field: `action`,css: { width: `80px`,position: `flex-end` },params: params => params.row.id,render: actionButtons },
  ]

  return (
    <PageLayout title="Orders" search={[searchText,setSearchText]} sort={[sortField,setSortField,columns]}>
      <OrderList>
        <Table
          loading={!usersLoaded || !hangersLoaded || !cloakroomsLoaded || !venuesLoaded || !ordersLoaded || !hangingsLoaded}
          columns={columns}
          rows={ordersWithCustomers}
          predicate={({ _row }) => currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === _row.hanging?.hanger?.cloakroom?.venueId)}
          searchText={searchText}
          sortField={sortField}
          MobileCard={MobileOrderCard}
          mobileCardFunctions={{ handleOpen,handleStatusChange }} />
      </OrderList>
      {selectedOrder ? (
        <Modal scroll title="Order Details" showModal={showModal} setShowModal={setShowModal}>
          <OrderDetailCard {...selectedOrder} userRole={USER_ROLES.ADMIN}/> 
        </Modal>
      ) : <></>}
    </PageLayout>
  )
}

export default Orders

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`
const OrderListItem = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
