import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { useCart } from '../hooks/useCart'
import { Navigate,useNavigate } from 'react-router-dom'
import CartSummary from '../components/CartSummary'
import { Container,DefaultCardStyle,EmptyStateContainer,EmptyStateIconContainer,EmptyStateText,PrimaryButton } from '../styles/CommonStyles'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useCollection } from '../hooks/useCollection'
import { API_URL } from '../data/constants'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from '../components/CheckoutForm'
import { stripePromise } from '../services/stripe'
import { COLORS } from '../utils/colors'
import { useCurrentHanging } from '../hooks/useCurrentHanging'
import { ROUTES } from '../routes/routes'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { BsPatchMinus } from 'react-icons/bs'

const Checkout = () => {
  const [clientSecret,setClientSecret] = useState(``)
  const [error,setError] = useState()
  const currentUser = useCurrentUser()
  const [currentHanging] = useCurrentHanging()
  const [products] = useCollection(`products`)
  const [inventory] = useCollection(`inventory`)
  const [cartItems,setCartItems] = useCart()
  const navigate = useNavigate()

  useEffect(() => {    
    (async() => {
      if(error) return
      if(!currentUser.isInitialized) return
      if(!cartItems.length) return
      if(!inventory.length) return
      if(clientSecret) return

      if(!currentHanging.hangingId) {
        toast.error(`Please make sure, you scan the right QR and it is still valid.`)
        return
      }

      const outOfStockItems = cartItems.filter(item => {
        const stock = inventory.filter(ele => ele.productId === item.id).reduce((acc,crr) => acc + crr.quantity,0)
        return !stock
      })

      if(outOfStockItems.length) {
        toast.error(`Some items in your cart are out of stock! Please remove them to continue.`)
        navigate(`/cart`)
        return
      }

      const paymentIntent = {
        items: cartItems,
        ...currentHanging,
      }

      if(currentUser.id) paymentIntent.userId = currentUser.id

      try {
        const res = await axios.post(`${API_URL}/create-payment-intent`,paymentIntent)
        if(res.data.done) navigate(`${ROUTES.PAYMENT_RESULT}?payment_intent_client_secret=${res.data.clientSecret}`)      
        if(res.data.clientSecret) setClientSecret(res.data.clientSecret)    
      } catch (error) {
        toast.error(error?.response?.data?.error || error.message)
        setError(error)
      }
    })()
  },[cartItems,currentUser.isInitialized,currentUser.id,currentHanging,inventory,clientSecret,navigate,error])

  if(cartItems.length === 0) 
    return <Navigate to={`/cart`} />

  const appearance = {
    theme: `night`,
    variables: {
      colorPrimary: `white`,
      colorBackground: COLORS.background,
      borderRadius: `4px`,
      focusBoxShadow: `none`,
      colorText: COLORS.text,
    },
    rules: {
      ".Input, .Block": {
        backgroundColor: `transparent`,
        padding: `12px 8px`,
        color: COLORS.onSurface,
        border: `1px solid ${COLORS.dividerOnSurface}`,
        boxShadow: `none`,
      },
    },
  }

  const options = {
    clientSecret,
    appearance,
  }

  return (
    <Container>
      <CartContainer>
        <LeftContainer>
          {error ? (
            <EmptyStateContainer $error>
              <BsPatchMinus />
              <EmptyStateText>Something went wrong.</EmptyStateText>
              <PrimaryButton onClick={() => setError()}>Try Again</PrimaryButton>
            </EmptyStateContainer>
          ) : clientSecret ? (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          ) : (
            <EmptyStateContainer>
              <EmptyStateIconContainer>
                <LoadingSpinner /> 
              </EmptyStateIconContainer>
            </EmptyStateContainer>
          )}
        </LeftContainer>
        <RightContainer>
          {cartItems.length > 0 ? <CartSummary /> : <></> }
        </RightContainer>
      </CartContainer>
      
    </Container>
  )
}

export default Checkout

const CartContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 32px;
  flex: 1;
  max-width: 1440px;
  
  @media (min-width: 1380px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
const LeftContainer = styled.div`
  ${DefaultCardStyle}
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`
const PaymentButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  padding: 12px;
  font-size: 14px;
  background-color: var(--success);
  color: var(--onSuccess);
  border: 0;
  border-radius: 4px;
  cursor: pointer;
`
const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
`

