import React,{ useState } from 'react'
import PageLayout from '../layouts/PageLayout'
import { useCollection } from '../hooks/useCollection'
import { deleteField,doc,setDoc } from 'firebase/firestore'
import { db } from '../services/firebase'
import Table from '../components/Table'
import styled from 'styled-components'
import { DefaultCardStyle,List,ListItem,SummaryItem,SummaryList } from '../styles/CommonStyles'
import SearchableDropwdown from '../components/SearchableDropdown'
import { toast } from 'react-toastify'
import { USER_ROLES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useCurrentUser } from '../hooks/useCurrentUser'

const MobileUserCard = ({
  id,displayName,email,phoneNumber,photoURL,role,venueId, 
}) => {
  return (
    <OrderListItem key={id}>
      <Image src={photoURL} />
      <Title>{displayName || `-`}</Title>
      <SummaryList>
        <SummaryItem>
          <span>Email</span>
          <div>{email}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Phone</span>
          <div>{phoneNumber || `-`}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Role</span>
          <div>{role || `-`}</div>
        </SummaryItem>
      </SummaryList>
      <ActionContainer>
        {/* <PrimaryButtonDestructive onClick={() => handleDelete(id)}>Delete</PrimaryButtonDestructive>
        <LinkButton style={{ flex: 1 }} to={`/products/${id}/edit`}>Edit</LinkButton> */}
      </ActionContainer>
    </OrderListItem>
  )
}

const VenueChangeSelect = ({ id,val,handleVenueChange,venues }) => {
  const options = []

  for (let index = 0; index < venues.length; index++) {
    const venue = venues[index]
    options.push({ value: venue.id,label: venue.name })
  }

  return (
    <SearchableDropwdown
      options={options}
      placeholder="Select Venue"
      value={options.filter(item => val?.filter(ele => ele === item.value).length)}
      onChange={e => handleVenueChange(id,e)}
      closeMenuOnSelect={false}
      isMulti/>
  )
}

const RoleChangeSelect = ({ id,val,handleRoleChange }) => {
  const options = []

  for (const [key,value] of Object.entries(USER_ROLES))
    value !== USER_ROLES.CLOAKROOM && options.push({ value,label: value })

  return (
    <SearchableDropwdown
      options={options}
      placeholder="Select Role"
      value={options.find(item => item.value === val)}
      onChange={e => handleRoleChange(id,e)}/>
  )
}

export const Users = () => {
  const currentUser = useCurrentUser()
  const [users,usersLoaded] = useCollection(`users`)
  const [venues,venuesLoaded] = useCollection(`venues`)
  const [searchText,setSearchText] = useState()
  const [sortField,setSortField] = useState({ field: `displayName`,isReverse: false })

  const handleVenueChange = async(userId,selectedVenues) => {
    if(currentUser.id === userId) 
      toast.warning(`You can't update your own user`)

    try {
      const venuesToSave = selectedVenues.map(item => item.value)
      await setDoc(doc(db,`users`,userId),{ venues: venuesToSave.length ? venuesToSave : deleteField(),role: venuesToSave.length ? USER_ROLES.CLOAKROOM : USER_ROLES.USER },{ merge: true })
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  const handleRoleChange = async(userId,selectedRole) => {
    if(currentUser.id === userId) 
      toast.warning(`You can't update your own user`)

    try {
      await setDoc(doc(db,`users`,userId),{ role: selectedRole.value,venueId: deleteField() },{ merge: true })
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  const columns = [
    { field: `photoURL`,css: { width: `120px` },type: `image`,title: `Photo`,render: val => <OrderListImage src={val} /> },
    { field: `displayName`,sortable: true,title: `Name`,render: val => val || `-` },
    { field: `email`,sortable: true,title: `Email` },
    { field: `phoneNumber`,sortable: true,title: `Phone`,render: val => val || `-` },
    {
      field: `venues`,
      title: `Venues`,
      css: { overflow: `unset` },
      params: params => ({ id: params.row.id,val: params.row.venues }),
      render: ({ id,val }) => currentUser.id !== id && <VenueChangeSelect id={id} val={val} handleVenueChange={handleVenueChange} venues={venues} />,
    },
    {
      field: `role`,
      sortable: true,
      title: `Role`,
      css: { overflow: `unset` },
      params: params => ({ id: params.row.id,val: params.row.role }),
      render: ({ id,val }) => val !== USER_ROLES.CLOAKROOM && currentUser.id !== id ? <RoleChangeSelect id={id} val={val} handleRoleChange={handleRoleChange} /> : val,
      sort: ({ val }) => val, 
    },
  ]

  return (
    <PageLayout title="Users" search={[searchText,setSearchText]} sort={[sortField,setSortField,columns]}>
      <OrderList>
        <Table
          loading={!usersLoaded || !venuesLoaded}
          loadingType="users"
          columns={columns} 
          rows={users}
          searchText={searchText}
          sortField={sortField}
          MobileCard={MobileUserCard} />
      </OrderList>
    </PageLayout>
  )
}

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`
const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;

  @media screen and (min-width: 576px) {
    height: 180px;
  }
  
  @media screen and (min-width: 1400px) {
    height: 200px;
  }
`
const Title = styled.h4`
  padding: 16px 0 8px 0;
  margin-bottom: 8px;
  font-size: 18px;
`
const Price = styled.div`
  font-size: 20px;
  line-height: 1;
  margin-bottom: 32px;
`
const OrderListContaier = styled.div`
  ${List}
`
const OrderListItem = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const OrderListImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: auto;
`
