import styled from 'styled-components'
import { Logo } from './Logo'
import { AiOutlineShoppingCart,AiOutlineClose } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Link,useLocation,useNavigate } from 'react-router-dom'
import ButtonWithBadge from './ButtonWithBadge'
import { useCartCount } from '../hooks/useCart'
import { useState,useEffect,Fragment } from 'react'
import UserGreeting from './UserGreeting'
import { sidebarLinks } from '../routes/sidebarLinks'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { USER_PERMISSONS } from '../data/constants'

const links = [
  {
    id: 1,
    name: `About`,
    url: `/about`,
  },
  {
    id: 2,
    name: `Contact`,
    url: `/contact`,
  },
]

export const NavBar = () => {
  const currentUser = useCurrentUser()
  const location = useLocation()
  const navigate = useNavigate()
  const [cartCount] = useCartCount()
  const [open,setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  },[location.pathname])

  return (
    <NavBarContainer>
      <NavBarWrapper>
        <TopLeft>
          <Logo/>
        </TopLeft>
        <TopRight>
          <Links>
            {links.map(link => {
              return (
                <LinkItem to={link.url} key={link.id} $showOnMobile={true}>
                  {link.name}
                </LinkItem>
              )
            })}
          </Links>

          <ButtonWithBadge Component={Link} to={`/cart`} count={cartCount}>
            <Cart color="#ffffff" size={30} />
          </ButtonWithBadge>
        </TopRight>

        <MobileCartLink Component={Link} to={`/cart`} count={cartCount}>
          <Cart color="#ffffff" size={30} />
        </MobileCartLink>
        
        <Burger>
          {!open && <GiHamburgerMenu onClick={() => setOpen(!open)} color="#ffffff" size={30} />}
          {open && <AiOutlineClose onClick={() => setOpen(!open)} color="#ffffff" size={30} />}
        </Burger>

        <BurgerMenu className={open ? `open` : ``}>
          <NavbarUserGreeting />
          <Links>
            {sidebarLinks.items.map((item,index) => {
              const { type,showOnMobile,route,icon,text } = item

              if(type === `protected` && !(currentUser.id && USER_PERMISSONS[currentUser.role?.toUpperCase()]?.includes(route.split(`/`)[1])))
                return <Fragment key={index}></Fragment>

              if(type === `auth` && !((!currentUser.id && route === `/login`) || (currentUser.id && route === `/logout`)))
                return <Fragment key={index}></Fragment>

              return (
                <LinkItem key={index} $showOnMobile={!showOnMobile} className={location.pathname === route ? `clicked` : ``} to={route} $renderLast={route === `/logout`}>
                  {text}
                </LinkItem>
              )
            })}
            {links.map(link => {
              return (
                <LinkItem to={link.url} key={link.id} $showOnMobile={true}>
                  {link.name}
                </LinkItem>
              )
            })}
          </Links>
          <Links>
            {sidebarLinks.settings.map((item,index) => {
              return (
                <LinkItem to={item.route} key={index} $showOnMobile={true}>
                  {item.text}
                </LinkItem>
              )
            })}
          </Links>

          {/* <ButtonWithBadge Component={Link} to={`/cart`} count={cartCount}>
            <Cart color="#ffffff" size={30} />
          </ButtonWithBadge> */}
        </BurgerMenu>
      </NavBarWrapper>
    </NavBarContainer>
  )
}

const NavBarContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 50px;
    background-color: var(--background);
    /* padding: .5em; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const NavBarWrapper = styled.div`
    height: 100%;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const TopLeft = styled.div``

const TopRight = styled.div`
  display: none;

  @media screen and (min-width: 576px) {
    display: flex;
    align-items: center;
  }
`
const Burger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  z-index: 9999;
  
  @media screen and (min-width: 576px) {
    display: none;
  }
`
const BurgerMenu = styled.div`
  position: fixed;
  top: 0;
  right: -2000px;
  z-index: 999;
  width: 100%;
  height: 100vh;
  padding-bottom: 64px;
  overflow-y: auto;
  background-color: var(--background);
  transition: all .5s ease-in-out;
  /* border-left: 1px solid #ffffff; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  text-align: center;

  &.open {
    right: 0;
  }

  @media screen and (min-width: 576px) {
    display: none;
  }
`
const NavbarUserGreeting = styled(UserGreeting)`
  align-self: stretch;
`
const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin-top: 64px;
  margin-bottom: auto;
  
  @media screen and (min-width: 576px) {
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin-top: 0;
    margin-right: 1em;
    border-right: 1px solid #ffffff;
  }
`
const LinkItem = styled(Link)`
  display: ${p => p.$showOnMobile ? `inline-flex` : `none`};
  font-size: 1.2em;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: all .2s ease-in-out;
  margin: 0 .5em;
  order: ${p => p.$renderLast ? 9 : 0};

  &:hover {
    color: var(--light);
    scale: 1.1;
  }
`
const CompanyName = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  color: white;
`
const Cart = styled(AiOutlineShoppingCart)`
  cursor: pointer;
  transition: all .2s ease-in-out;
  /* margin-bottom: 3rem; */

  &:hover {
    color: var(--light);
    scale: 1.1;
  }

  @media screen and (min-width: 576px) {
    margin-bottom: 0;
  }
`
const MobileCartLink = styled(ButtonWithBadge)`
  display: flex;
  align-items: flex-start;
  height: 30px;
  margin-left: auto;
  margin-right: 16px;

  @media screen and (min-width: 576px) {
    display: none;
  }
`
