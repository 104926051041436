import styled from 'styled-components'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'
import { CloakroomAddOrUpdate } from '../components/CloakroomAddOrUpdate'

export const NewCloakroom = () => {
  return (
    <PageLayout title="New Cloakroom">
      <PageContainerDivided>
        <CloakroomAddOrUpdate />
      </PageContainerDivided>
    </PageLayout>
  )
}
