import { useState,useEffect,useRef } from 'react'

const useDropdownVisible = initialIsVisible => {
  const [isDropdownVisible,setIsDropdownVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) 
      setIsDropdownVisible(false) 
  }

  useEffect(() => {
    document.addEventListener(`click`,handleClickOutside,true)
    return () => {
      document.removeEventListener(`click`,handleClickOutside,true)
    }
  },[])

  return { ref,isDropdownVisible,setIsDropdownVisible }
}

export default useDropdownVisible
