import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCardStyle,Form,FormError,FormItem,FormLabel,FormRow,Input,PrimaryButton, 
} from '../styles/CommonStyles'
import { useNavigate } from 'react-router-dom'
import { useDoc } from '../hooks/useDoc'
import { addDoc,collection,doc,setDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from '../services/firebase'
import { useCollection } from '../hooks/useCollection'
import { FIREBASE_ERROR_MESSAGES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object({
  name: yup.string().required(`This field is required`).max(80,`This field must be at most 80 characters`),
  location: yup.string().required(`This field is required`),
}).required()

export const VenueAddOrUpdate = ({ id }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(schema) })
  const [venues] = useCollection(`venues`)
  const venue = useDoc(`venues/${id}`)
  const navigate = useNavigate()

  useEffect(() => {
    if(venue.id) {
      setValue(`name`,venue.name)
      setValue(`location`,venue.location)
    }
    
    // else         
    //   navigate(`/404`)
  },[setValue,venue])

  const handleSave = async({ name,location }) => {
    if(venues.find(item => item.name === name && item.id !== id))
      return toast.error(`Venue with this name already exists!`)

    const venueToSave = { name,location: location.replace(/\s/g,``) }

    try {
      if(id) {
        await setDoc(doc(db,`venues`,id),venueToSave)
        toast.success(`Venue updated successfully`)
      } else {
        await addDoc(collection(db,`venues`),venueToSave)
        toast.success(`Venue saved successfully`)
      }

      navigate(`/venues`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <>
      <LeftContainer>
        <Form onSubmit={handleSubmit(handleSave)}>
          <FormRow>
            <FormItem>
              <FormLabel>
                Name
              </FormLabel>
              <Input {...register(`name`)} />
              <FormError>{errors.name?.message}</FormError>
            </FormItem>
            <FormItem>
              <FormLabel>
                Location Link
              </FormLabel>
              <Input {...register(`location`)} placeholder="E.g. https://www.google.com/maps/place/O2+Academy+Brixton/@51.4651626,-0.1149344,15z..." />
              <FormError>{errors.name?.message}</FormError>
            </FormItem>
          </FormRow>         
          <FormRow>
            <Button $loading={isSubmitting}>{id ? `Update Venue` : `Create Venue`}</Button>
          </FormRow>
        </Form>
      </LeftContainer>
      <RightContainer>
      </RightContainer>
    </>
  )
}

const LeftContainer = styled.div`
  ${DefaultCardStyle}
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  color: var(--onSurface);
`
