import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { HangerAddOrUpdate } from '../components/HangerAddOrUpdate'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'

export const EditHanger = () => {
  const { id } = useParams()

  return (
    <PageLayout title="Edit Hanger">
      <PageContainerDivided>
        <HangerAddOrUpdate id={id} />
      </PageContainerDivided>
    </PageLayout>
  )
}
