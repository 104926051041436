import { createUserWithEmailAndPassword,getAuth,signInWithEmailAndPassword } from 'firebase/auth'
import { app } from '../services/firebase'

export const useEmailAuth = () => {
  const auth = getAuth(app)

  const signInWithEmail = (email,password,isRegister) => {
    if(isRegister) 
      return createUserWithEmailAndPassword(auth,email,password)
    else
      return signInWithEmailAndPassword(auth,email,password)
  }

  return signInWithEmail
}
