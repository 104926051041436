import styled from 'styled-components'
import { useDoc } from '../hooks/useDoc'
import { useCollection } from '../hooks/useCollection'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useEffect,useMemo } from 'react'
import { collection,doc,setDoc } from 'firebase/firestore'
import { db } from '../services/firebase'
import { toast } from 'react-toastify'
import { Form,FormError,FormItem,FormLabel,FormRow,PrimaryButton } from '../styles/CommonStyles'
import SearchableDropwdown from './SearchableDropdown'
import { USER_ROLES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'

const schema = yup.object({ selectedCloakroom: yup.object({ value: yup.string().required(`This field is required`) }) }).required()

export const UpdateHangingLocationCard = ({ id,closeModal }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(schema) })
  const currentUser = useCurrentUser()
  const hanging = useDoc(`hangings/${id}`)
  const hanger = useDoc(`hangers/${hanging?.hangerId}`)
  const [cloakrooms] = useCollection(`cloakrooms`)

  const cloakroomOptions = useMemo(() => cloakrooms.filter(item => item.venueId === hanging?.venueId).filter(item => currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === item.venueId)).map(item => ({ value: item.id,label: item.number })),[hanging?.venueId,cloakrooms,currentUser.role,currentUser.venues])

  useEffect(() => {
    if(hanger.id) 
      setValue(`selectedCloakroom`,cloakroomOptions.find(item => item.value === hanger?.cloakroomId))
  },[cloakroomOptions,hanger?.cloakroomId,hanger.id,setValue])

  const handleSave = async({ selectedCloakroom }) => {
    const hangerToSave = { cloakroomId: selectedCloakroom.value }

    try {
      await setDoc(doc(db,`hangers`,hanger?.id),hangerToSave,{ merge: true })
      toast.success(`Location updated successfully`)
   
      closeModal()
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(handleSave)}>
        <FormRow />
        <FormRow>
          <FormItem>
            <FormLabel>
              Cloakroom
            </FormLabel>
            <Controller
              control={control}
              name="selectedCloakroom"
              render={({ field }) => (<SearchableDropwdown
                options={cloakroomOptions}
                placeholder="Select Cloakroom"
                value={field.value}
                onChange={field.onChange}/>)}/>
            <FormError>{errors.selectedCloakroom?.value?.message}</FormError>
          </FormItem>
        </FormRow>
        <FormRow>
          <Button $loading={isSubmitting}>Update Location</Button>
        </FormRow>
      </Form>
    </Container>
  )
}

const Container = styled.div`
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
