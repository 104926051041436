import styled from 'styled-components'
import { Container,PageContainerDivided } from '../styles/CommonStyles'
import { useParams } from 'react-router-dom'
import { VenueAddOrUpdate } from '../components/VenueAddOrUpdate'
import PageLayout from '../layouts/PageLayout'

export const EditVenue = () => {
  const { id } = useParams()

  return (
    <PageLayout title="Edit Venue">
      <PageContainerDivided>
        <VenueAddOrUpdate id={id} />
      </PageContainerDivided>
    </PageLayout>
  )
}
