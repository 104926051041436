import { getAuth,onAuthStateChanged } from 'firebase/auth'
import { app } from '../services/firebase'
import { useEffect,useState } from 'react'

export const useAuth = () => {
  const [auth,setAuth] = useState()
  const firebaseAuth = getAuth(app)

  useEffect(() => {
    onAuthStateChanged(firebaseAuth,user => {
      setAuth(firebaseAuth)

      // if(user)
      //   user.getIdToken().then(accessToken => {
      //     sessionStorage.setItem(`accessToken`,accessToken)
      //   })
      // else
      //   sessionStorage.removeItem(`accessToken`)
    })
  },[firebaseAuth])

  return auth
}
