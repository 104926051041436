import React,{ useState } from 'react'
import styled from 'styled-components'
// import { Label } from './Label'
import Select,{ components } from 'react-select'
import { validate } from '../utils/validate'
import { InputError } from '../styles/CommonStyles'

const SearchableDropwdown = ({
  options,
  value,
  schema,
  onChange,
  ...props
}) => {
  const [touched,setTouched] = useState(false)
  const errors = validate(value?.value,schema || {})

  return (
    <Container>
      <DropdownContainer
        classNamePrefix={`Select`}
        options={options}
        value={value}
        required={schema?.required}
        hideSelectedOptions={false}
        onBlur={() => setTouched(true)}
        onChange={onChange}
        components={{ MultiValue }}
        {...props}/>
      {!!errors.length && touched && <InputError className="error">
        {errors.map(e => e.message).join(`, `)}
      </InputError>}
    </Container>
  )
}

export default SearchableDropwdown

const MoreSelectedBadge = ({ items }) => {
  const title = items.join(`, `)
  const { length } = items
  const label = `${length} item${length !== 1 ? `s` : ``} selected`

  return (
    <CountBadge title={title}>
      {label}
    </CountBadge>
  )
}

const MultiValue = ({ index,getValue,...props }) => {
  const maxToShow = 0
  const overflow = getValue()
    .slice(maxToShow)
    .map(x => x.label)

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
`
const DropdownContainer = styled(Select)`
  mix-blend-mode: normal;
  width: 100%;
  height: 46px;
  text-align: ${p => p.centered ? `center` : `left`};
  border: none;
  border-radius: 4px;
  color: var(--onSurface);
  background-color: var(--surface);
  border: 1px solid var(--dividerOnSurface);
  font-size: 14px;


  &:hover,
  &:focus {
    outline: none;
  }

  &.Select--is-disabled {
    opacity: 0.45;
  }

  .Select__control,
  .Select__control:hover {
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color: var(--onSurface);
  }

  .Select__single-value,
  .Select__input-container {
    color: var(--onSurface);
  }

  .Select__menu {
    color: var(--onSurface);
    background-color: var(--surface);
  }

  .Select__option.Select__option--is-focused {
    background-color: var(--dark);
  }

  .Select__option.Select__option--is-selected {
    background-color: var(--light);
  }

  .Select__indicator-separator {
    background-color: var(--dividerOnSurface);
  }

  /* Select__option Select__option--is-focused Select__option--is-selected css-tr4s17-option */
`
const CountBadge = styled.div`
  margin-left: auto;
  background: var(--dividerOnSurface);
  border-radius: 4px;
  font-size: 10px;
  padding: 4px 8px;
`
