import React,{ useCallback,useEffect,useMemo,useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCardStyle,Form,FormError,FormItem,FormLabel,FormRow,Input,PrimaryButton,ResetButton,
} from '../styles/CommonStyles'
import { useNavigate } from 'react-router-dom'
import { useDoc } from '../hooks/useDoc'
import {
  addDoc,collection,doc,getDocs,query,setDoc,where, 
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import SearchableDropwdown from './SearchableDropdown'
import { useCollection } from '../hooks/useCollection'
import { db,parseCollectionSnapshot } from '../services/firebase'
import { FIREBASE_ERROR_MESSAGES,HANGING_STATUS,USER_ROLES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { mergeCollections } from '../utils/mergeCollections'

const schema = yup.object({ selectedHanger: yup.object({ value: yup.string().required(`This field is required`) }) }).required()

export const HangingAddOrUpdate = ({ id }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,getValues,control } = useForm({ resolver: yupResolver(schema) })
  const [venues] = useCollection(`venues`)
  const [hangers] = useCollection(`hangers`)
  const [cloakrooms] = useCollection(`cloakrooms`)
  const hanging = useDoc(`hangings/${id}`)
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  const venuesForHangers = useMemo(() => venues.reduce((acc,crr) => ({ ...acc,[crr.id]: crr }),{}),[venues])

  const hangersWithVenues = useMemo(() => mergeCollections(hangers,{ collection: cloakrooms,field: `id`,on: `cloakroomId`,name: `cloakroom`,single: true },{ collection: venues,field: `id`,on: `cloakroom.venueId`,name: `cloakroom.venue`,single: true }),[cloakrooms,hangers,venues])

  const hangerOptions = useMemo(() => hangersWithVenues.filter(item => currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === item.cloakroom?.venueId)).map(item => ({ value: item.id,label: `${item.cloakroom?.number}/${item.number} (${venuesForHangers[item.cloakroom?.venueId]?.name})` })),[currentUser,hangersWithVenues,venuesForHangers])

  useEffect(() => {
    if(hanging.id) 
      setValue(`selectedHanger`,hangerOptions.find(item => item.value === hanging.hangerId))
    // else
    //   navigate(`/404`)
  },[setValue,hangerOptions,hanging])

  const handleSave = async({ selectedHanger }) => {
    const conditions = [
      where(`hangerId`,`==`,selectedHanger.value),
      where(`status`,`!=`,HANGING_STATUS.RETURNED),
      (currentUser.role !== USER_ROLES.ADMIN && where(`venueId`,`in`,currentUser.venues)),
    ].filter(item => item)

    const q = query(collection(db,`hangings`),...conditions)
    const hangings = parseCollectionSnapshot(await getDocs(q)).filter(item => item.id != id)

    if(hangings.length)
      return toast.error(`Sorry, this hanger is already in use!`)

    try {
      if(id) {
        await setDoc(doc(db,`hangings`,id),{ hangerId: selectedHanger.value },{ merge: true })
        toast.success(`Stashed updated successfully`)
      } else {
        await addDoc(collection(db,`hangings`),{
          hangerId: selectedHanger.value,
          venueId: hangers.find(item => item.id === selectedHanger.value).venueId,
          createdAt: new Date(),
          status: HANGING_STATUS.CREATED,
        })
        toast.success(`Stashed saved successfully`)
      }

      navigate(`/hangings`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <>
      <LeftContainer>
        <Form onSubmit={handleSubmit(handleSave)}>
          <FormRow>
            <FormItem full>
              <FormLabel>
                Hanger
              </FormLabel>
              <Controller
                control={control}
                name="selectedHanger"
                render={({ field }) => (<SearchableDropwdown
                  options={hangerOptions}
                  placeholder="Select Hanger"
                  value={field.value}
                  onChange={field.onChange}/>)}/>
              <FormError>{errors.selectedHanger?.value?.message}</FormError>
            </FormItem>
          </FormRow>
          <FormRow>
            <Button $loading={isSubmitting}>{id ? `Update Stashed` : `Create Stashed`}</Button>
          </FormRow>
        </Form>
      </LeftContainer>
      <RightContainer>
      </RightContainer>
    </>
  )
}

const LeftContainer = styled.div`
  ${DefaultCardStyle}
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  color: var(--onSurface);
`
