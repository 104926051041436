import { atom,useAtom } from 'jotai'
import _ from 'lodash'
import { collection,onSnapshot } from 'firebase/firestore'
import { db,parseCollectionSnapshot } from '../services/firebase'
import { useState } from 'react'

export const collectionsAtom = atom({})

export const colSubscriptions = {}

export const colSubscriptionsLoaded = {}

export const useCollection = name => {
  const [collections,setCollections] = useAtom(collectionsAtom)
  if(!name) return [[],true]
  if(colSubscriptions[name]) 
    return [collections[name] || [],colSubscriptionsLoaded[name]]

  colSubscriptions[name] = onSnapshot(collection(db,name),snapshot => {
    console.log(`---GOT COLLECTION SNAPSHOT ${name}---`)
    setCollections(s => {
      const output = _.cloneDeep(s)
      output[name] = parseCollectionSnapshot(snapshot)
      colSubscriptionsLoaded[name] = true
      return output
    })
  })
  return [collections[name] || [],colSubscriptionsLoaded[name]]
}
