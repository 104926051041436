import { useCallback,useEffect,useState } from 'react'
import { db,getPushToken,onMessageListener } from '../services/firebase'
import { toast } from 'react-toastify'
import { InAppNotification } from '../components/InAppNotification'
import { useAtom } from 'jotai'
import { deleteField,doc,setDoc } from 'firebase/firestore'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useShowNotificationModal } from './useShowNotificationModal'
import { atomWithLocalStorage } from '../utils/atomWithLocalStorage'
import { isSupported } from 'firebase/messaging'
import { useCurrentUser } from './useCurrentUser'
import { useCurrentHanging } from './useCurrentHanging'

const pushPermission = atomWithLocalStorage(`pushPermission`,``)
export const usePushPermission = () => useAtom(pushPermission)

const pushToken = atomWithLocalStorage(`pushToken`,``)
export const usePushToken = () => useAtom(pushToken)

export const useFirebasePush = () => {
  const [loading,setLoading] = useState(false)
  const [isPushSupported,setIsPushSupported] = useState(false)
  const [showNotificationModal,setShowNotificationModal] = useShowNotificationModal()
  const [permission,setPermission] = usePushPermission()
  const [token,setToken] = usePushToken()
  const currentUser = useCurrentUser()
  const [currentHanging] = useCurrentHanging()

  const saveToken = async(token,userId,hangingId,venueId) => {
    try {
      const tokenToSave = {
        hangingId: hangingId || deleteField(),
        venueId: venueId || deleteField(),
      }

      if(userId) tokenToSave.userId = userId

      await setDoc(doc(db,`pushTokens`,token),tokenToSave,{ merge: true })
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  useEffect(() => {
    (async() => {
      const _isSupported = await isSupported()
      setIsPushSupported(_isSupported)
    })()
  },[])

  const initialize = useCallback(async() => {
    if(!isPushSupported) return
    if(!currentUser.isInitialized) return
    setLoading(true)

    setPermission(Notification.permission)

    // default,denied,granted
    if(Notification.permission === `granted`) {
      const token = await getPushToken()
      if(token) {
        setToken(token)
        await saveToken(token,currentUser.id,currentHanging.hangingId,currentHanging.venueId)
      }
    }

    setLoading(false)
  },[setPermission,setToken,isPushSupported,currentUser.id,currentUser.isInitialized,currentHanging.hangingId,currentHanging.venueId])

  const requestPermission = useCallback(async() => {
    if(!isPushSupported) return
    if(!currentUser.isInitialized) return
    setLoading(true)

    const token = await getPushToken()

    setPermission(Notification.permission)

    if(token) {
      setToken(token)
      await saveToken(token,currentUser.id,currentHanging.hangingId,currentHanging.venueId)
    }

    setLoading(false)
  },[setPermission,setToken,isPushSupported,currentUser.id,currentUser.isInitialized,currentHanging.hangingId,currentHanging.venueId])
  
  const onPushMessage = useCallback(async() => {
    if(!isPushSupported) return

    onMessageListener(payload => {
      const { notification } = payload
      console.log(`--notification--`,notification)
      toast.success(<InAppNotification {...notification}/>,{ icon: false,autoClose: false })
    })
  },[isPushSupported])
  
  return {
    isPushSupported,token,permission,initialize,requestPermission,onPushMessage,loading,
  }
}
