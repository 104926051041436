import styled from 'styled-components'

export const HangingHistoryCard = ({ id,history }) => {
  return (
    <Container>
      <List>
        {history ? history.map((item,index) => {
          const { title,time } = item

          return (
            <Item key={index}>
              <span>{title}</span>
              <span>{time}</span>
            </Item>
          )
        }) : (
          <div>No history</div>
        )}
      </List>
    </Container>
  )
}

const Container = styled.div`
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dividerOnBackground);
  }
`
