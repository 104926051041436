import React,{ useRef,useState } from 'react'
import styled from 'styled-components'
import { AiOutlineDelete } from 'react-icons/ai'
import { IconButton } from '../styles/CommonStyles'
import { ref,uploadBytes } from 'firebase/storage'
import { getImageUrlFromFullPath,storage } from '../services/firebase'
import { v4 as uuidv4 } from 'uuid'
import { LoadingSpinner } from './LoadingSpinner'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'

const Dropzone = ({ image,setImage,folder }) => {
  const [isLoading,setIsLoading] = useState(false)
  const inputRef = useRef(null)

  const handleChange = async e => {
    const [file] = e.target.files
    if (file) {
      setIsLoading(true)
      const fullPath = `${folder}/${uuidv4()}-${file.name}`
      const reference = ref(storage,fullPath)

      try {
        await uploadBytes(reference,file).then(snapshot => {
          const realUrl = getImageUrlFromFullPath(fullPath)
          setImage(realUrl)
        })
      } catch (error) {
        handleFirebaseErrors(eror)
      } finally {       
        setIsLoading(false)
      }
    }
  }

  const handleRemove = () => {
    inputRef.current.value = null
    setImage(``)
  }

  return (
    <StyledDropzone>
      <FileInput
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
        onChange={handleChange}
        disabled={isLoading}
        ref={inputRef}/>

      <Label>
        {isLoading ? <LoadingSpinner /> : image ? <PreviewImage src={image}/> : <span>Select an image</span>}
      </Label>

      {image ? <DeleteImageButton $destructive onClick={handleRemove}><AiOutlineDelete size={20} /></DeleteImageButton> : <></>}
    </StyledDropzone>
  )
}

export default Dropzone

const StyledDropzone = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 260px;
  background-color: var(--background);
  border-radius: 4px;
  cursor: pointer;
`

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 16px;
  opacity: 0;
`
const Label = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  margin: 16px;
  border-radius: 4px;
  border: 1px dashed var(--dividerOnSurface);
`
const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const DeleteImageButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 5;
  background-color: var(--surface);
  border: 1px solid var(--dividerOnSurface);
`
