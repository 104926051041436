import styled from 'styled-components'

export const InAppNotification = ({ title,body,image }) => {
  return (
    <Container>
      {image ? <Image src={image} /> : <></>}
      <Content>
        <Title>{title}</Title>
        <Body>{body}</Body>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 16px;
`
const Image = styled.img`
  width: 72px;
  align-self: stretch;
  object-fit: cover;
  border-radius: 4px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const Title = styled.h3`
  margin: 0;
  font-size: 16px;
`
const Body = styled.p`
  margin: 0;
  font-size: 14px;
`
