import _ from 'lodash'
import { useDoc } from './useDoc'
import { useEffect,useState } from 'react'
import { HANGING_STATUS,HANGING_STATUS_ENUM } from '../data/constants'
import { useCurrentVenue } from './useCurrentVenue'
import { doc,setDoc } from 'firebase/firestore'
import { db } from '../services/firebase'
import { useCurrentUser } from './useCurrentUser'
import { useCurrentHanging } from './useCurrentHanging'

export const useSetVenue = () => {
  const currentUser = useCurrentUser()
  const [currentVenue,setCurrentVenue] = useCurrentVenue()
  const [currentHanging,setCurrentHanging] = useCurrentHanging()
  const hangingDoc = useDoc(`hangings/${currentHanging.hangingId}`)
  const hangerDoc = useDoc(`hangers/${hangingDoc?.hangerId}`)
  const cloakroomDoc = useDoc(`cloakrooms/${hangerDoc?.cloakroomId}`)
  const venueDoc = useDoc(`venues/${cloakroomDoc?.venueId}`)

  useEffect(() => {
    if(hangingDoc.id && hangerDoc.id && cloakroomDoc.id && venueDoc.id) {
      const res = _.cloneDeep(venueDoc)
      res.cloakroom = _.cloneDeep(cloakroomDoc)
      res.hanger = _.cloneDeep(hangerDoc)
      res.hanging = _.cloneDeep(hangingDoc)
      setCurrentVenue(res)
    }
  },[setCurrentVenue,hangerDoc,cloakroomDoc,venueDoc,hangingDoc])

  useEffect(() => {
    (async() => {
      if(hangingDoc.id && !hangingDoc.userId && currentUser.id)
        await setDoc(doc(db,`hangings`,hangingDoc.id),{ userId: currentUser.id },{ merge: true })
    })()
  },[hangingDoc,currentUser])

  useEffect(() => {
    if(hangingDoc.status !== undefined && hangingDoc.status !== HANGING_STATUS.OUT) {
      setCurrentHanging({})
      setCurrentVenue({})
    }
  },[hangingDoc.status,setCurrentHanging,setCurrentVenue])
}
