import styled from 'styled-components'
import { dateFormat,priceFormat } from '../utils/format'
import { ORDER_STATUS_ENUM,USER_ROLES } from '../data/constants'
import { useCollection } from '../hooks/useCollection'
import { mergeCollections } from '../utils/mergeCollections'

const OrderDetailCard = ({
  id,customer,date,total,status,items,userRole,vat,...rest
}) => {

  const [products] = useCollection(`products`)

  const productsWithData = mergeCollections(items,{ collection: products,field: `id`,on: `id`,name: `details`,single: true })

  return (
    <StyledOrderDetail {...rest}>
      <Summary>
        {userRole === USER_ROLES.ADMIN && <SummaryItem>
          <span>Customer</span>
          <span>{customer?.displayName || `-`}</span>
        </SummaryItem>}
        <SummaryItem>
          <span>Status</span>
          <span>{ORDER_STATUS_ENUM[status]}</span>
        </SummaryItem>
        <SummaryItem>
          <span>Date</span>
          <span>{dateFormat(date)}</span>
        </SummaryItem>
      </Summary>
      <ProductList>
        {productsWithData.map(product => {
          const { thumbnail,title } = product?.details || {}
          return (
            <Product key={product.id}>
              <ProductImage src={thumbnail} />
              <ProductNameCount>
                <span>{title}</span>
                <span>x</span>
                <span>{product.count}</span>
              </ProductNameCount>
              <ProductPrice>{priceFormat(product.price * product.count)}</ProductPrice>
            </Product>
          )
        })}
        <ProductVat>
          VAT: {priceFormat(vat)}
        </ProductVat>
        <ProductTotal>
          Total: {priceFormat(total)}
        </ProductTotal>
      </ProductList>
    </StyledOrderDetail>
  )
}

export default OrderDetailCard

const StyledOrderDetail = styled.div`
`
const Summary = styled.div`
  max-width: 320px;
`
const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dividerOnBackground);
  }
`
const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`
const Product = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dividerOnBackground);
  }
`
const ProductNameCount = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const ProductImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;
`
const ProductPrice = styled.div`
  margin-left: auto;
`
const ProductVat = styled.div`
  align-self: flex-end;
  padding: 12px 0;
  font-size: 12px;
`

const ProductTotal = styled.div`
  align-self: flex-end;
  margin-top: 16px;
`
