import { sortBy } from './sortBy'

export const filterAndSort = (data,searchText,sortField) => {
  if(!searchText && !sortField) return data

  let newData = [...data]

  if(searchText)
    newData = newData.filter(item => JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase()))

  if(sortField)
    newData.sort(sortBy(sortField.field,sortField.isReverse))

  return newData
}
