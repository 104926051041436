import React from 'react'
import {
  Container,DefaultCard,H1,P3,P4,PageContainer,PageHeader,PageHeaderAction,PageHeaderActions,PageHeaderTitleContainer, 
} from '../styles/CommonStyles'
import Search from '../components/Search'
import SortDropdown from '../components/SortDropdown'

const PageLayout = ({ title,children,search,sort,loading,...rest }) => {
  return (
    <Container {...rest}>
      <PageContainer>
        {!loading ? <PageHeader>
          <PageHeaderTitleContainer>
            <H1>{title}</H1>
          </PageHeaderTitleContainer>
          <PageHeaderActions>
            {search && <PageHeaderAction>
              <Search searchText={search[0]} setSearchText={search[1]} />
            </PageHeaderAction>}
            {sort && <PageHeaderAction>
              <SortDropdown sortField={sort[0]} setSortField={sort[1]} fields={sort[2]} />
            </PageHeaderAction>}
          </PageHeaderActions>
        </PageHeader> : <></>}
        {children}
      </PageContainer>
    </Container>
  )
}

export default PageLayout
