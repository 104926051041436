import _ from 'lodash'
import React,{ useMemo } from 'react'
import styled from 'styled-components'
import { EmptyStateContainer,EmptyStateText,List } from '../styles/CommonStyles'
import useWindowSize from '../hooks/useWindowSize'
import { getDepthValue } from '../utils/getDepthValue'
import { filterAndSort } from '../utils/filterAndSort'
import { BsViewList } from 'react-icons/bs'
import { SummaryListLoading } from './SummaryListLoading'
import { CustomContentLoader } from './CustomContentLoader'

const prepareData = (columns,data) => {
  const res = data.map((row,i) => {
    return (columns.reduce((acc,crr) => {
      const { field,params,render,sort } = crr

      const content = params ? params({ row,crr }) : getDepthValue(row,field)
      const val = sort ? sort(content) : content

      const col = _.cloneDeep(acc)
      _.set(col,field,val)
      return col
    },{ _row: row }))
  })
  return res
}

const Table = ({
  loading,loadingType,columns,rows,searchText,sortField,predicate = () => true,MobileCard,mobileCardFunctions,...rest 
}) => {
  const { width } = useWindowSize()

  const dataToFilter = prepareData(columns,rows)

  const data = useMemo(() => filterAndSort(dataToFilter,searchText,sortField).filter(predicate),[dataToFilter,searchText,sortField,predicate])

  if(MobileCard && width < 992 && loading)
    return(
      <MobileCardContainer>
        {[...Array(6)].map((item,index) => <SummaryListLoading key={index} type={loadingType} />)}
      </MobileCardContainer>
    )

  if(loading)
    return (
      <StyledTable {...rest}>
        <THead>
          <TR $noHover>
            {columns.map(column => {
              const { field,title,css } = column
              return <TH key={field} css={css}>{title}</TH>
            })}
          </TR>
        </THead>
        <TBody>
          {[...Array(6)].map((row,i) => {
            return (
              <TR key={i}>
                {columns.map((column,r) => {
                  const { css } = column
                  return <TD key={i + r} css={css}>
                    <CustomContentLoader width="100" style={{ maxWidth: `100%` }} viewBox="0 0 100 16">
                      <rect x="0" y="0" rx="4" ry="4" width="100" height="16" />
                    </CustomContentLoader>
                  </TD>
                })}
              </TR>
            )
          })}
        </TBody>
      </StyledTable>
    )

  if(!data.length) 
    return (
      <EmptyStateContainer>
        <BsViewList />
        <EmptyStateText>There are no items in this list!</EmptyStateText>
      </EmptyStateContainer>
    )

  if(MobileCard && width < 992)
    return (
      <MobileCardContainer>
        {data.map((item,index) => <MobileCard key={index} {...item._row} {...mobileCardFunctions} />)}
      </MobileCardContainer>
    )

  return (
    <StyledTable {...rest}>
      <THead>
        <TR $noHover>
          {columns.map(column => {
            const { field,title,css } = column
            return <TH key={field} css={css}>{title}</TH>
          })}
        </TR>
      </THead>
      <TBody>
        {data.map((row,i) => {
          return (
            <TR key={i}>
              {columns.map((column,r) => {
                const { field,title,type,css,params,render = content => content } = column
                let content = params ? params({ row: row._row,column }) : getDepthValue(row._row,field)
                return <TD key={i + r} css={css}>{render(content) || `-`}</TD>
              })}
            </TR>
          )
        })}
      </TBody>
    </StyledTable>
  )
}

export default Table

const StyledTable = styled.div`
  width: 100%;
  color: var(--onSurface);
  background-color: var(--surface);
  border-radius: 8px;
`
const THead = styled.div`
  border-bottom: 1px solid var(--dividerOnSurface);
`
const TFoot = styled.div`
`
const TBody = styled.div`
`
const TR = styled.div`
  display: flex;

  &:last-child {

    border-radius: 0 0 8px 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--dividerOnSurface);
  }

  &:hover {
    ${p => !p.$noHover && `background-color: var(--dividerOnSurface);` }
  }
`
const TH = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: ${({ css }) => css?.position || `flex-start`};
  ${({ css }) => !css?.width ? `flex: 1;` : ``}
  width: ${({ css }) => css?.width ? css.width : `auto`};
  
  @media screen and (min-width: 1200px) {
  }
`
const TD = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  padding: 16px;
  justify-content: ${({ css }) => css?.position || `flex-start`};
  ${({ css }) => !css?.width ? `flex: 1;` : ``}
  width: ${({ css }) => css?.width ? css.width : `auto`};
  white-space: nowrap;
  overflow: ${({ css }) => css?.overflow ? css.overflow : `hidden`};;
  text-overflow: ellipsis;

  @media screen and (min-width: 1200px) {
  }
`
const MobileCardContainer = styled.div`
  ${List}
`
