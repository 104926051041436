import React,{ useEffect,useState } from 'react'
import { BsPatchCheck,BsPatchExclamation,BsPatchMinus } from 'react-icons/bs'
import styled from 'styled-components'
import {
  Container,DefaultCard,EmptyStateContainer,EmptyStateIcon,EmptyStateIconContainer,EmptyStateText,LinkButton, 
} from '../styles/CommonStyles'
import { useAuth } from '../hooks/useAuth'
import { Elements,useStripe } from '@stripe/react-stripe-js'
import { stripePromise } from '../services/stripe'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { useCart } from '../hooks/useCart'

const Result = () => {
  const auth = useAuth()
  const stripe = useStripe()
  const [status,setStatus] = useState(`processing`)
  const [,setCartItems] = useCart()
  // const [message,setMessage] = useState(null)

  useEffect(() => {
    if (!stripe) 
      return

    const clientSecret = new URLSearchParams(window.location.search).get(
      `payment_intent_client_secret`,
    )

    if (!clientSecret) 
      return

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setStatus(paymentIntent.status)
      if(paymentIntent.status === `succeeded`)
        setCartItems([])
    })
  },[stripe,setCartItems])

  if(status === `succeeded`)
    return (
      <EmptyStateContainer $success>
        <BsPatchCheck />
        <EmptyStateText>Payment Successfull</EmptyStateText>
        {auth?.currentUser ? <LinkButton to={`/my-orders`}>Go to Orders Page</LinkButton> : <LinkButton to={`/`}>Go to Home</LinkButton>}
      </EmptyStateContainer>
    )

  if(status === `processing`)
    return (
      <EmptyStateContainer>
        <EmptyStateIconContainer>
          <LoadingSpinner /> 
        </EmptyStateIconContainer>
        <EmptyStateText>Your payment is processing..</EmptyStateText>
      </EmptyStateContainer>
    )

  if(status === `requires_payment_method`)
    return (
      <EmptyStateContainer $error>
        <BsPatchExclamation />
        <EmptyStateText>Your payment was not successful, please try again.</EmptyStateText>
        <LinkButton to={`/checkout`}>Go to Checkout</LinkButton>
      </EmptyStateContainer>
    )

  return (
    <EmptyStateContainer $error>
      <BsPatchMinus />
      <EmptyStateText>Something went wrong.</EmptyStateText>
    </EmptyStateContainer>
  )
}

export const PaymentResult = () => {
  const auth = useAuth()

  return (
    <Container>
      <CartContainer>
        <Elements stripe={stripePromise}>
          <Result />
        </Elements>
        {/* <EmptyStateContainer>
          <BsPatchCheck />
          <EmptyStateText>Payment Successfull</EmptyStateText>
          {auth?.currentUser ? <LinkButton to={`/my-orders`}>Go to Orders Page</LinkButton> : <LinkButton to={`/`}>Go to Home</LinkButton>}
        </EmptyStateContainer> */}
      </CartContainer>
    </Container>
  )
}

const CartContainer = styled(DefaultCard)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
  max-width: 1440px;
  
  @media (min-width: 1380px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
