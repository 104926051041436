import { useMemo } from 'react'
import styled from 'styled-components'
import { priceFormat } from '../utils/format'
import { useHandleCart } from '../hooks/useHandleCart'
import { CircleButton } from '../styles/CommonStyles'

export const CartItem = ({ id,title,price,stock,thumbnail,inventory }) => {
  const { cartProduct,handleAddToCart,handleRemoveFromCart } = useHandleCart(id)

  const totalInventory = inventory?.reduce((acc,crr) => acc + crr.quantity,0)
  const cantBuy = cartProduct?.count >= totalInventory
  const warningText = !totalInventory ? `Out of stock!` : cantBuy ? `You can't get more of this!` : totalInventory <= 10 ? `Only ${totalInventory} left!` : ``

  return (
    <StyledCartItem>
      <Image src={thumbnail} />
      <TitleContainer>
        <Title>{title && title.length > 20 ? `${title.substring(0,20)}...` : title}</Title>
        <Price>{priceFormat(price)}</Price>
        <ItemWarning>
          {warningText}
        </ItemWarning>
      </TitleContainer>
      <ActionContainer>
        <CountContainer>
          <CircleButton onClick={handleRemoveFromCart}>-</CircleButton>
          <Count>{cartProduct?.count}</Count>
          <CircleButton onClick={handleAddToCart} disabled={cantBuy}>+</CircleButton>
        </CountContainer>
      </ActionContainer>
    </StyledCartItem>
  )
}

const StyledCartItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-basis: 100%;
  padding: 16px;
  box-sizing: border-box;
  
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
`
const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
`
const Title = styled.h4`
  margin-bottom: 8px;
  font-size: 16px;

  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
`
const Price = styled.span`
  display: block;
  font-size: 20px;
  line-height: 1;
  /* margin-top: auto; */
  margin-bottom: 8px;

  @media screen and (min-width: 576px) {
    font-size: 24px;
  }
`
const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  margin-left: auto;
`
const CountContainer = styled.div`
  display: flex;
  align-items: center;
`
const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 100%;
  line-height: 0;
`
const ItemWarning = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: var(--error);
`
