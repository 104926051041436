import ContentLoader from 'react-content-loader'

export const CustomContentLoader = ({ children,...rest }) => (
  <ContentLoader
    backgroundColor="rgba(255,255,255,.1)"
    foregroundColor="rgba(255,255,255,.25)"
    {...rest}>
    {children}
  </ContentLoader>
)
