import styled from 'styled-components'
import ProductAddOrUpdate from '../components/ProductAddOrUpdate'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'

const NewProduct = () => {
  return (
    <PageLayout title="New Product">
      <PageContainerDivided>
        <ProductAddOrUpdate />
      </PageContainerDivided>
    </PageLayout>
  )
}

export default NewProduct
