import styled from 'styled-components'
import { VenueAddOrUpdate } from '../components/VenueAddOrUpdate'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'

export const NewVenue = () => {
  return (
    <PageLayout title="New Venue">
      <PageContainerDivided>
        <VenueAddOrUpdate />
      </PageContainerDivided>
    </PageLayout>
  )
}
