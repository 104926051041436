import { BsPlusSquare } from 'react-icons/bs'
import { MdIosShare } from 'react-icons/md'
import styled from 'styled-components'
import { EmptyStateContainer,Step,StepBlue } from '../styles/CommonStyles'
import { AiOutlineScan } from 'react-icons/ai'

export const HomeHowTo = () => {
  return (
    <HowToEmptyStateContainer>
      <Icon />
      <Container>
        <Description>
          To use the application, please follow the instructions
        </Description>
        <Steps>
          <HowToStep>1. First, open the camera on your device.</HowToStep>
          <HowToStep>2. Scan the QR code provided by the cloakroom and simply tap on the link that appears.</HowToStep>
          <HowToStep>3. You will be redirected back to this page.</HowToStep>
        </Steps>
        {/* <Description $small>
          To use the application you need to scan the QR Code provided by the cloakroom.
        </Description> */}
      </Container>
    </HowToEmptyStateContainer>
  )
}

const Container = styled.div`
  text-align: left;
  max-width: 480px;

  @media screen and (min-width: 576px) {
    text-align: center;
  }
`
const Icon = styled(AiOutlineScan)`
  margin-bottom: 48px;
  font-size: 160px !important;
`
const Steps = styled.div`
  margin: 32px 0;
`
const Description = styled.p`
  font-size: ${p => p.$small ? `16px` : `18px`};
`
const HowToEmptyStateContainer = styled(EmptyStateContainer)`
  padding-top: 32px;
  padding-left: 0;
  padding-right: 0;
`
const HowToStep = styled(Step)`
  border-color: var(--dividerOnDarkest) !important;
`
