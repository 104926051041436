import { Navigate,useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { USER_PERMISSONS } from '../data/constants'

export const ProtectedRoute = ({ children }) => {
  const currentUser = useCurrentUser()
  const auth = useAuth()
  const location = useLocation()

  if(auth && !auth?.currentUser)
    return <Navigate to={`/login`} />

  if(auth && auth?.currentUser && USER_PERMISSONS[currentUser.role?.toUpperCase()]?.includes(location.pathname.split(`/`)[1]) === false)
    return <Navigate to={`/403`} />
  
  // TODO: add logic of initialization so user doesnt see flashing screen
  // if (!isInitialized) 
  //   return <LoadingScreen />

  return (
    <>
      {children}
    </>
  )
}
