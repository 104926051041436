import styled from 'styled-components'
import { DefaultCardStyle,ListItem,SummaryItem,SummaryList } from '../styles/CommonStyles'
import { CustomContentLoader } from './CustomContentLoader'

const SummaryContentLoader = () => (
  <CustomContentLoader width="100px" viewBox="0 0 100 16">
    <rect x="0" y="0" rx="4" ry="4" width="100" height="16" />
  </CustomContentLoader>
)

export const SummaryListLoading = ({ type }) => {

  if(type === `home`) return (
    <Card>
      <Image>
        <CustomContentLoader width="100%" height="100%">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="200" />
        </CustomContentLoader>
      </Image>
      <Title>
        <CustomContentLoader width="130" viewBox="0 0 130 20">
          <rect x="0" y="0" rx="4" ry="4" width="130" height="20" />
        </CustomContentLoader>
      </Title>
      <CustomContentLoader width="100" viewBox="0 0 100 20">
        <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
      </CustomContentLoader>
    </Card>
  )

  if(type === `venues`) return (
    <Card>
      <Title>
        <CustomContentLoader width="130" viewBox="0 0 130 20">
          <rect x="0" y="0" rx="4" ry="4" width="130" height="20" />
        </CustomContentLoader>
      </Title>
      <Price>
        <CustomContentLoader width="130" viewBox="0 0 130 20">
          <rect x="0" y="0" rx="4" ry="4" width="130" height="20" />
        </CustomContentLoader>
      </Price>
      <CustomContentLoader width="100%" viewBox="0 0 100 16">
        <rect x="0" y="0" rx="2" ry="2" width="100" height="14" />
      </CustomContentLoader>
    </Card>
  )

  if(type === `products`) return (
    <Card>
      <Image>
        <CustomContentLoader width="100%" height="100%">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="200" />
        </CustomContentLoader>
      </Image>
      <Title>
        <CustomContentLoader width="130" viewBox="0 0 130 20">
          <rect x="0" y="0" rx="4" ry="4" width="130" height="20" />
        </CustomContentLoader>
      </Title>
      <Price>
        <CustomContentLoader width="130" viewBox="0 0 130 20">
          <rect x="0" y="0" rx="4" ry="4" width="130" height="20" />
        </CustomContentLoader>
      </Price>
      <CustomContentLoader width="100%" viewBox="0 0 100 16">
        <rect x="0" y="0" rx="2" ry="2" width="100" height="14" />
      </CustomContentLoader>
    </Card>
  )

  if(type === `users`) return (
    <Card>
      <Image>
        <CustomContentLoader width="100%" height="100%">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="200" />
        </CustomContentLoader>
      </Image>
      <Title>
        <CustomContentLoader width="130" viewBox="0 0 130 20">
          <rect x="0" y="0" rx="4" ry="4" width="130" height="20" />
        </CustomContentLoader>
      </Title>
      <SummaryList>
        {[...Array(3)].map((asd,i) => (
          <SummaryItem key={i}>
            <span><SummaryContentLoader /></span>
            <div><SummaryContentLoader /></div>
          </SummaryItem>
        ))}
      </SummaryList>
    </Card>
  )

  return (
    <Card>
      <SummaryList style={{ marginBottom: 16 }}>
        {[...Array(6)].map((asd,i) => (
          <SummaryItem key={i}>
            <span><SummaryContentLoader /></span>
            <div><SummaryContentLoader /></div>
          </SummaryItem>
        ))}
      </SummaryList>
      <CustomContentLoader width="100%" viewBox="0 0 100 16">
        <rect x="0" y="0" rx="2" ry="2" width="100" height="14" />
      </CustomContentLoader>
    </Card>
  )
}

const Card = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const Image = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 4px 4px 0 0;

  @media screen and (min-width: 576px) {
    height: 180px;
  }

  @media screen and (min-width: 1400px) {
    height: 200px;
  }
`
const Title = styled.h4`
  padding: 16px 0 8px 0;
  margin-bottom: 8px;
  font-size: 18px;
`
const Price = styled.div`
  font-size: 20px;
  line-height: 1;
  margin-bottom: 32px;
`
