import { useNavigate } from 'react-router'
import styled from 'styled-components'

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <H1>404 Not Found</H1>
      <Button onClick={() => navigate(`/`)}>Go To Home</Button>
    </Container>
  )
}

export default NotFound

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
`
const H1 = styled.h1`
  color: var(--light);
  text-align: center;
`
const Button = styled.button`
  background: var(--dark);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;

  &:hover {
    background: var(--darkest);
  }
`
