import { createGlobalStyle } from 'styled-components'
import { DefaultCardStyle } from './CommonStyles'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    background: var(--darkest);
  }
  a {
    display: inline-flex;
    justify-content: center;
    text-decoration: none;
    color: inherit;
  }
  .table-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .Toastify__toast-container--top-right {
    top: 50px;

    @media screen and (min-width: 576px) {
      top: calc(50px + 16px);
    }
  }
  .Toastify__toast-theme--dark {
    background-color: var(--backdropDark);
    backdrop-filter: blur(4px);
  }
  #react-confirm-alert {
    position: relative;
    z-index: var(--toastify-z-index);
    
    .react-confirm-alert-overlay {
      background-color: var(--backdrop);
      backdrop-filter: blur(2px);
      animation: react-confirm-alert-fadeIn 0.1s 0.1s forwards;
    }

    .react-confirm-alert {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 8px;
      pointer-events: none;

      > * {
        pointer-events: initial;
      }
    }

    .react-confirm-alert-body {
      ${DefaultCardStyle}
      border-radius: 8px;
      border: 1px solid var(--dividerOnSurface);
    }

    .react-confirm-alert-body > h1 {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }
`

export default GlobalStyle
