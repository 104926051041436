import { useCallback,useMemo,useState } from 'react'
import styled from 'styled-components'
import { priceFormat } from '../utils/format'
import { useHandleCart } from '../hooks/useHandleCart'
import { CircleButton,DefaultCardStyle,ListItem,PrimaryButtonCss } from '../styles/CommonStyles'

export const ProductItem = ({ id,title,price,stock,thumbnail,inventory }) => {
  const { cartProduct,handleAddToCart,handleRemoveFromCart } = useHandleCart(id)

  if(!inventory?.length) return <></>

  const totalInventory = inventory.reduce((acc,crr) => acc + crr.quantity,0)

  const cantBuy = cartProduct?.count >= totalInventory
  const warningText = !totalInventory ? `Out of stock!` : cantBuy ? `You can't get more of this!` : totalInventory <= 10 ? `Only ${totalInventory} left!` : ``

  return (
    <StyledProductItem>
      <Image src={thumbnail} />
      <Title>{title}</Title>
      <ItemWarning>
        {warningText}
      </ItemWarning>
      <ActionContainer>
        <Price>{priceFormat(price)}</Price>
        {cartProduct && cartProduct.count ? (
          <CountContainer>
            <CircleButton onClick={handleRemoveFromCart}>-</CircleButton>
            <Count>{cartProduct.count}</Count>
            <CircleButton onClick={handleAddToCart} disabled={!totalInventory || cantBuy}>+</CircleButton>
          </CountContainer>
        ) : (
          <Button onClick={handleAddToCart} disabled={!totalInventory || cantBuy}>Add to Cart</Button>
        )}
      </ActionContainer>
    </StyledProductItem>
  )
}

const StyledProductItem = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;
  border: 1px solid var(--dividerOnBackground);

  @media screen and (min-width: 576px) {
    height: 180px;
  }
  
  @media screen and (min-width: 1400px) {
    height: 200px;
  }
`
const Title = styled.h4`
  padding: 8px 0 8px 0;
  margin-bottom: 12px;
  font-size: 18px;
`
const Price = styled.span`
  font-size: 20px;
  line-height: 1;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`
const Button = styled.button`
  ${PrimaryButtonCss}
  height: 32px;
  padding: 4px 8px;
  font-size: 14px;
  background-color: var(--light);
  ${p => p.disabled && `opacity: 0.5`}
`
const CountContainer = styled.div`
  display: flex;
  align-items: center;
`
const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 100%;
  line-height: 0;
`
const ItemWarning = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: var(--error);
`
