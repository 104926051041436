import React,{ useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCardStyle,IconButton,IconLink,LinkButton,ListItem,PageAdd,PrimaryButton,PrimaryButtonDestructive,SummaryItem,SummaryList, 
} from '../styles/CommonStyles'
import Table from '../components/Table'
import { dateFormat } from '../utils/format'
import { AiOutlineDelete,AiOutlineEdit,AiOutlinePlusCircle,AiOutlineShoppingCart } from 'react-icons/ai'
import Modal from '../components/Modal'
import { HANGING_STATUS,HANGING_STATUS_ENUM,USER_ROLES } from '../data/constants'
import PageLayout from '../layouts/PageLayout'
import SearchableDropwdown from '../components/SearchableDropdown'
import { useCollection } from '../hooks/useCollection'
import { mergeCollections } from '../utils/mergeCollections'
import { toast } from 'react-toastify'
import {
  collection,deleteDoc,deleteField,doc,getDocs,query,setDoc,where,
} from 'firebase/firestore'
import { db,parseCollectionSnapshot } from '../services/firebase'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { HangingHistoryCard } from '../components/HangingHistoryCard'
import { ConfirmDialog } from '../components/ConfirmDialog'
import { confirmAlert } from 'react-confirm-alert'
import { UpdateHangingLocationCard } from '../components/UpdateHangingLocationCard'
import { BiMap } from 'react-icons/bi'
import { useQueryCollection } from '../hooks/useQueryCollection'
import OrderDetailCard from '../components/OrderDetailCard'

const MobileHangingCard = ({
  id,hanger,orders,customer,scannedAt,returnedAt,status,handleDelete,handleStatusChange,handleLocationChange,handleOrdersOpen,
}) => {
  return (
    <OrderListItem key={id}>
      <SummaryList>
        <SummaryItem>
          <span>Venue</span>
          <div>{hanger?.cloakroom?.venue?.name}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Location</span>
          <div>{hanger?.cloakroom?.number}/{hanger?.number}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Scanned At</span>
          <div>{scannedAt ? dateFormat(scannedAt) : `-`}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Returned At</span>
          <div>{returnedAt ? dateFormat(returnedAt) : `-`}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Customer</span>
          <div>{customer?.displayName || `-`}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Status</span>
          <div>{status === HANGING_STATUS.CREATED ? <>{HANGING_STATUS_ENUM[status]}</> : <StatusChangeSelect id={id} val={status} hangerId={hanger?.id} handleStatusChange={handleStatusChange} />}</div>
        </SummaryItem>
      </SummaryList>
      <ActionContainer>
        <PrimaryButtonDestructive onClick={() => handleDelete(id)}>Delete</PrimaryButtonDestructive>
        <PrimaryButton style={{ flex: 1 }} onClick={() => handleLocationChange(id)}>Change Location</PrimaryButton>
        {orders.length ? <PrimaryButton onClick={() => handleOrdersOpen(id)}><AiOutlineShoppingCart size={18} /></PrimaryButton> : <></>}
        {/* <LinkButton style={{ flex: 1 }} to={`/hangings/${id}/edit`}>Edit</LinkButton> */}
      </ActionContainer>
    </OrderListItem>
  )
}

const StatusChangeSelect = ({ id,val,hangerId,handleStatusChange }) => {
  const options = []

  for (const [key,value] of Object.entries(HANGING_STATUS_ENUM)) 
    if(+key !== HANGING_STATUS.CREATED) options.push({ value: +key,label: value })

  return (
    <SearchableDropwdown
      options={options}
      placeholder="Select Status"
      value={options.find(item => item.value === val)}
      onChange={e => handleStatusChange(id,e,hangerId)}/>
  )
}

export const Hangings = () => {
  const currentUser = useCurrentUser()
  const [orders,ordersLoaded] = useQueryCollection(`orders`,[[`venueId`,`in`,currentUser.venues]],currentUser.role === USER_ROLES.ADMIN && [])
  const [hangings,hangingsLoaded] = useQueryCollection(`hangings`,[[`venueId`,`in`,currentUser.venues]],currentUser.role === USER_ROLES.ADMIN && [])
  const [hangers,hangersLoaded] = useCollection(`hangers`)
  const [cloakrooms,cloakroomsLoaded] = useCollection(`cloakrooms`)
  const [users,usersLoaded] = useCollection(`users`)
  const [venues,venuesLoaded] = useCollection(`venues`)
  const [searchText,setSearchText] = useState()
  const [sortField,setSortField] = useState({ field: `createdAt`,isReverse: true })
  const [selectedHanging,setSelectedHanging] = useState()
  const [showModal,setShowModal] = useState(false)
  const [showOrdersModal,setShowOrdersModal] = useState(false)

  const hangingsWithData = mergeCollections(
    hangings,
    { collection: users,field: `id`,on: `userId`,name: `customer`,single: true },
    { collection: hangers,field: `id`,on: `hangerId`,name: `hanger`,single: true },
    { collection: cloakrooms,field: `id`,on: `hanger.cloakroomId`,name: `hanger.cloakroom`,single: true },
    { collection: venues,field: `id`,on: `hanger.cloakroom.venueId`,name: `hanger.cloakroom.venue`,single: true },
    { collection: orders,field: `hangingId`,on: `id`,name: `orders` },
  )

  const handleOrdersOpen = id => {
    const hanging = hangingsWithData.find(item => item.id === id)
    setSelectedHanging(hanging)
    setShowOrdersModal(true)
  }

  const handleLocationChange = id => {
    const hanging = hangingsWithData.find(item => item.id === id)
    setSelectedHanging(hanging)
    setShowModal(true)
  }

  const handleDelete = id => {
    const onConfirm = async() => {
      try {
        await deleteDoc(doc(db,`hangings`,id))
        toast.success(`Stashed deleted successfully`)
      } catch (error) {
        handleFirebaseErrors(error) 
      }
    }

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDialog title="Delete Stashed" message="Are you sure you want to delete?" confirmText="Yes, Delete it!" onClose={onClose} onConfirm={onConfirm} />
      ),
    })
  }

  const handleStatusChange = async(id,selectedStatus,hangerId) => {
    try {
      const conditions = [
        where(`hangerId`,`==`,hangerId),
        where(`status`,`!=`,HANGING_STATUS.RETURNED),
        (currentUser.role !== USER_ROLES.ADMIN && where(`venueId`,`in`,currentUser.venues)),
      ].filter(item => item)

      const q = query(collection(db,`hangings`),...conditions)
      const hangings = parseCollectionSnapshot(await getDocs(q)).filter(item => item.id != id)

      if(selectedStatus.value != HANGING_STATUS.RETURNED && hangings.length)
        return toast.error(`Sorry, this hanger is already in use!`)

      const hangingToUpdate = {
        status: selectedStatus.value,
        returnedAt: selectedStatus.value === HANGING_STATUS.RETURNED ? new Date() : deleteField(),
      }
      // TODO: if status is returned clear pushTokens venueId and hangingId
      await setDoc(doc(db,`hangings`,id),hangingToUpdate,{ merge: true })
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  const actionButtons = ({ id,orders }) => {
    return (
      <div className="table-actions">
        {orders.length ? <IconButton $circle onClick={() => handleOrdersOpen(id)}><AiOutlineShoppingCart size={18} /></IconButton> : <></>}
        <IconButton $circle onClick={() => handleLocationChange(id)}><BiMap size={18} /></IconButton>
        {/* <IconLink $circle to={`/hangings/${id}/edit`}><AiOutlineEdit size={18} /></IconLink> */}
        <IconButton $circle $destructive onClick={() => handleDelete(id)}><AiOutlineDelete size={18} /></IconButton>
      </div>
    )
  }

  const columns = [
    { field: `hanger.cloakroom.venue.name`,sortable: true,title: `Venue` },
    {
      field: `location`,
      sortable: true,
      title: `Location`,
      // css: { width: `140px` },
      params: params => ({ cloakroomNumber: params.row.hanger.cloakroom.number,hangerNumber: params.row.hanger.number }),
      render: ({ cloakroomNumber,hangerNumber }) => `${cloakroomNumber}/${hangerNumber}`,
      sort: ({ cloakroomNumber,hangerNumber }) => +`${cloakroomNumber}${hangerNumber}`, 
    },
    { field: `createdAt`,sortable: true,title: `Created At`,render: val => dateFormat(val) },
    { field: `scannedAt`,sortable: true,title: `Scanned At`,render: val => dateFormat(val) },
    { field: `customer.displayName`,sortable: true,title: `Customer` },
    {
      field: `status`,
      sortable: true,
      title: `Status`,
      css: { overflow: `unset` },
      params: params => ({ id: params.row.id,val: params.row.status,hangerId: params.row.hangerId }),
      render: ({ id,val,hangerId }) => val === HANGING_STATUS.CREATED ? <>{HANGING_STATUS_ENUM[val]}</> : <StatusChangeSelect id={id} val={val} hangerId={hangerId} handleStatusChange={handleStatusChange} />,
      sort: ({ val }) => HANGING_STATUS_ENUM[val],
    },
    { field: `action`,css: { position: `flex-end` },params: params => params.row,render: actionButtons },
  ]

  return (
    <PageLayout title="Stashed" search={[searchText,setSearchText]} sort={[sortField,setSortField,columns]}>
      <PageAdd>
        <IconLink to={`/hangings/new`}>
          <AiOutlinePlusCircle size={20} />
          <span>Add new</span>
        </IconLink>
      </PageAdd>
      <OrderList>
        <Table
          loading={!ordersLoaded || !hangingsLoaded || !hangersLoaded || !cloakroomsLoaded || !usersLoaded || !venuesLoaded}
          columns={columns} 
          rows={hangingsWithData}
          predicate={({ _row }) => currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === _row.hanger?.cloakroom?.venueId)}
          searchText={searchText}
          sortField={sortField}
          MobileCard={MobileHangingCard}
          mobileCardFunctions={{ handleDelete,handleStatusChange,handleLocationChange,handleOrdersOpen }} />
      </OrderList>
      {selectedHanging ? (
        <Modal title={`Update Location (${selectedHanging.id})`} showModal={showModal} setShowModal={setShowModal}>
          <UpdateHangingLocationCard {...selectedHanging} closeModal={() => setShowModal(false)} userRole={USER_ROLES.ADMIN}/>
        </Modal>
      ) : <></>}
      {selectedHanging ? (
        <Modal scroll title={`Orders`} showModal={showOrdersModal} setShowModal={setShowOrdersModal}>
          {selectedHanging.orders.map(order => {
            return <ModalOrderDetailCard key={order.id} {...order} /> 
          })}
        </Modal>
      ) : <></>}
    </PageLayout>
  )
}

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`
const OrderListItem = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`
const ModalOrderDetailCard = styled(OrderDetailCard)`
  padding: 16px;
  background-color: #111;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`
