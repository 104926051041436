import styled from 'styled-components'
import { Button } from './Button'
import { useGoogleAuth } from '../hooks/useGoogleAuth'
import { useEffect,useState } from 'react'
import googleLogo from '../assets/googleLogo.svg'
import facebookLogo from '../assets/facebookLogo.svg'
import { Link,useNavigate,useSearchParams } from 'react-router-dom'
import { useFacebookAuth } from '../hooks/useFacebookAuth'
import { useEmailAuth } from '../hooks/useEmailAuth'
import { toast } from 'react-toastify'
import { doc,getDoc,setDoc } from 'firebase/firestore'
import { FIREBASE_ERROR_MESSAGES,USER_ROLES } from '../data/constants'
import { db } from '../services/firebase'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Form,PrimaryButton,FormRow,FormLabel,FormError,FormItem,Input,
} from '../styles/CommonStyles'
import { getAuth,sendPasswordResetEmail } from 'firebase/auth'

const loginSchema = yup.object({
  email: yup.string().email(`PLease enter a valid email`).required(`This field is required`),
  password: yup.string().required(`This field is required`),
}).required()

const LoginWithEmail = ({ handleEmailClick }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(loginSchema) })
  const auth = getAuth()

  return (
    <Form onSubmit={handleSubmit(handleEmailClick)}>
      <FormRow>
        <FormItem full>
          <Input placeholder="you@example.com" {...register(`email`)} />
          <FormError>{errors.email?.message}</FormError>
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem full>
          <Input placeholder="Password" type="password" {...register(`password`)} />
          <FormError>{errors.password?.message}</FormError>
          <ForgotPassword>
            <Link to={`/forgot-password`}>Forgot your password?</Link>
          </ForgotPassword>
        </FormItem>
      </FormRow>
      <PrimaryButton $loading={isSubmitting}>
        Sign in with Email
      </PrimaryButton>
    </Form>
  )
}

const registerSchema = yup.object({
  email: yup.string().email(`PLease enter a valid email`).required(`This field is required`),
  password: yup.string().required(`This field is required`),
  passwordConfirm: yup.string().required(`This field is required`).oneOf([yup.ref(`password`),null],`Passwords must match`),
}).required()

const RegisterWithEmail = ({ handleEmailClick }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(registerSchema) })

  return (
    <Form onSubmit={handleSubmit(handleEmailClick)}>
      <FormRow>
        <FormItem full>
          <Input placeholder="you@example.com" {...register(`email`)} />
          <FormError>{errors.email?.message}</FormError>
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem full>
          <Input placeholder="Password" type="password" {...register(`password`)} />
          <FormError>{errors.password?.message}</FormError>
        </FormItem>
      </FormRow>
      <FormRow>
        <FormItem full>
          <Input placeholder="Confirm Password" type="password" {...register(`passwordConfirm`)} />
          <FormError>{errors.passwordConfirm?.message}</FormError>
        </FormItem>
      </FormRow>
      <PrimaryButton $loading={isSubmitting}>
        Register with Email
      </PrimaryButton>
    </Form>
  )
}

const AuthForm = ({ isRegister }) => {
  const signInWithGoogle = useGoogleAuth()
  const signInWithFacebook = useFacebookAuth()
  const signInWithEmail = useEmailAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const redirectTo = searchParams.get(`redirect`) || `/`

  const saveUser = async auth => {
    if(!auth?.user) return
  
    const { uid,displayName,email,phoneNumber,photoURL } = auth.user

    const refDoc = doc(db,`users`,uid)
    const docSnap = await getDoc(refDoc)

    if(docSnap.exists()) 
      await setDoc(refDoc,{
        displayName,
        email,
        phoneNumber,
        photoURL,
      },{ merge: true })
    else 
      await setDoc(refDoc,{
        displayName,
        email,
        phoneNumber,
        photoURL,
        role: USER_ROLES.USER,
      })

    navigate(redirectTo)
  }

  const handleGoogleClick = async() => {
    signInWithGoogle()
      .then(saveUser)
      .catch(handleFirebaseErrors)
  }

  const handleFacebookClick = async() => {
    signInWithFacebook()
      .then(saveUser)
      .catch(handleFirebaseErrors)
  }

  const handleEmailClick = async({ email,password }) => {
    signInWithEmail(email,password,isRegister)
      .then(saveUser)
      .catch(handleFirebaseErrors)
  }

  const text = isRegister ? `Register` : `Sign in`

  return (
    <Container>
      <CompanyName onClick={() => navigate(`/`)}>
        Stash AI
      </CompanyName>
      <Title>{text}</Title>
      <Card>
        <PrimaryButton $alignLeft onClick={handleGoogleClick}>
          <BrandLogo src={googleLogo}/>
          <span>{text} with Google</span>
        </PrimaryButton>
        <PrimaryButton $alignLeft onClick={handleFacebookClick}>
          <BrandLogo src={facebookLogo}/>
          <span>{text} with Facebook</span>
        </PrimaryButton>
        <p>or</p>
        {isRegister ? <RegisterWithEmail handleEmailClick={handleEmailClick} /> : <LoginWithEmail handleEmailClick={handleEmailClick} />}
        {redirectTo === `/checkout` ? <GuestLink to={`/checkout`}>Continue as a guest</GuestLink> : <></>}
      </Card>

      {isRegister ? (
        <OtherContainer>
          You already have an account? {` `}
          <Link to={`/login`}>Login</Link>
        </OtherContainer>
      ) : (
        <OtherContainer>
          You don&apos;t have an account? {` `}
          <Link to={`/register`}>Register</Link>
        </OtherContainer>
      )}
    </Container>
  )
}

export default AuthForm

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 100vh;
  min-height: 800px;

  p{
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
`
const Card = styled.div`
  background-color: var(--darkest);
  width: 100%;
  max-width: 350px;
  border-radius: 1em;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const BrandLogo = styled.img`
  width: 24px;
  height: 24px;
`
const CompanyName = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`
const GuestLink = styled(Link)`
  margin-top: 24px;
  text-decoration: underline;
`
const OtherContainer = styled.div`
  margin-top: 48px;
  font-size: 14px;
  text-align: center;
  color: var(--white);
  
  a {
    text-decoration: underline;
  }
`
const Title = styled.div`
  font-size: 28px;
  color: var(--onBackground);
  width: 100%;
  max-width: 350px;
  padding: 16px 30px;
`
const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  color: var(--onWhite);
  font-size: 14px;
  margin-bottom: 12px;
`
