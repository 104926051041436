import React,{ useEffect,useMemo,useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCardStyle,Form,FormError,FormItem,FormLabel,FormRow,Input,PrimaryButton,ResetButton,
} from '../styles/CommonStyles'
import { useNavigate } from 'react-router-dom'
import { useDoc } from '../hooks/useDoc'
import { addDoc,collection,doc,setDoc } from 'firebase/firestore'
import { toast } from 'react-toastify'
import SearchableDropwdown from './SearchableDropdown'
import { useCollection } from '../hooks/useCollection'
import { db } from '../services/firebase'
import { FIREBASE_ERROR_MESSAGES,USER_ROLES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useForm,Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object({
  selectedProduct: yup.object({ value: yup.string().required(`This field is required`) }),
  selectedVenue: yup.object({ value: yup.string().required(`This field is required`) }),
  quantity: yup.number().required().positive(`Quantity must be a positive number`).integer(`Quantity must be an integer`).typeError(`Please enter a valid number`),
}).required()

const InventoryAddOrUpdate = ({ id }) => {
  const { register,handleSubmit,formState: { errors,isSubmitting },setValue,control } = useForm({ resolver: yupResolver(schema) })
  const [products] = useCollection(`products`)
  const [venues] = useCollection(`venues`)
  const inventory = useDoc(`inventory/${id}`)
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  const productOptions = useMemo(() => products.map(item => ({ value: item.id,label: item.title })),[products])  
  const venueOptions = useMemo(() => venues.filter(item => currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === item.id)).map(item => ({ value: item.id,label: item.name })),[venues,currentUser])

  useEffect(() => {
    if(inventory.id) {
      setValue(`selectedProduct`,productOptions.find(item => item.value === inventory.productId))
      setValue(`selectedVenue`,venueOptions.find(item => item.value === inventory.venueId))
      setValue(`quantity`,inventory.quantity)
    }
    // else         
    //   navigate(`/404`)
  },[setValue,productOptions,venueOptions,inventory])

  const handleSave = async({ selectedProduct,selectedVenue,quantity }) => {
    const inventoryToSave = {
      productId: selectedProduct.value,
      venueId: selectedVenue.value,
      quantity,
    }

    try {
      if(id) {
        await setDoc(doc(db,`inventory`,id),inventoryToSave)
        toast.success(`Inventory updated successfully`)
      } else {
        await addDoc(collection(db,`inventory`),inventoryToSave)
        toast.success(`Inventory saved successfully`)
      }

      navigate(`/inventory`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <>
      <LeftContainer>
        <Form onSubmit={handleSubmit(handleSave)}>
          <FormRow>
            <FormItem>
              <FormLabel>
                Product
              </FormLabel>
              <Controller
                control={control}
                name="selectedProduct"
                render={({ field }) => (<SearchableDropwdown
                  options={productOptions}
                  placeholder="Select Product"
                  value={field.value}
                  onChange={field.onChange}/>)}/>
              <FormError>{errors.selectedProduct?.value?.message}</FormError>
            </FormItem>
            <FormItem>
              <FormLabel>
                Venue
              </FormLabel>

              <Controller
                control={control}
                name="selectedVenue"
                render={({ field }) => (<SearchableDropwdown
                  options={venueOptions}
                  placeholder="Select Venue"
                  value={field.value}
                  onChange={field.onChange}/>)}/>
              <FormError>{errors.selectedVenue?.value?.message}</FormError>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <FormLabel>
                Quantity
              </FormLabel>
              <Input type="number" step="any" {...register(`quantity`)} />
              <FormError>{errors.quantity?.message}</FormError>
            </FormItem>
            <FormItem></FormItem>
          </FormRow>
          <FormRow>
            <Button $loading={isSubmitting}>{id ? `Update Inventory` : `Create Inventory`}</Button>
          </FormRow>
        </Form>
      </LeftContainer>
      <RightContainer>
      </RightContainer>
    </>
  )
}

export default InventoryAddOrUpdate

const LeftContainer = styled.div`
  ${DefaultCardStyle}
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  color: var(--onSurface);
`
