import styled from 'styled-components'
import { BsBasket } from 'react-icons/bs'
import { CartItem } from '../components/CartItem'
import { useCart } from '../hooks/useCart'
import CartSummary from '../components/CartSummary'
import { Container,EmptyStateContainer,EmptyStateText } from '../styles/CommonStyles'
import { useCollection } from '../hooks/useCollection'
import { mergeCollections } from '../utils/mergeCollections'

const Cart = () => {
  const [products] = useCollection(`products`)
  const [inventory] = useCollection(`inventory`)
  const [cartItems] = useCart()

  const productsWithInventory = mergeCollections(products,{ collection: inventory,field: `productId`,on: `id`,name: `inventory` })

  return (
    <Container>
      <CartContainer>
        {cartItems.length > 0 ? 
          <>
            <LeftContainer>
              {cartItems.map(item => {
                const product = productsWithInventory.find(productItem => productItem.id === item.id)
                return <CartItem key={item.id} {...product} id={item.id} />
              })}
            </LeftContainer>
            <RightContainer>
              <CartSummary showCheckoutButton />
            </RightContainer>
          </>
          :
          <EmptyStateContainer>
            <BsBasket />
            <EmptyStateText>There are no items in your cart</EmptyStateText>
          </EmptyStateContainer>
        }
      </CartContainer>
    </Container>
  )
}

export default Cart

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
  max-width: 1440px;
  
  @media (min-width: 1380px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
const LeftContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  color: var(--onSurface);
  background-color: var(--surface);
  border-radius: 4px;
`
const RightContainer = styled.div`
  display: flex;
  flex-grow: 1;
`
