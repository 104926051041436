import { FacebookAuthProvider,signInWithPopup,getAuth } from 'firebase/auth'
import { app } from '../services/firebase'

export const useFacebookAuth = () => {
  const provider = new FacebookAuthProvider()
  const auth = getAuth(app)
  return () => {
    return signInWithPopup(auth,provider)
  }
}
