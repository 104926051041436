import React,{ useRef } from 'react'
import styled from 'styled-components'
import { ResetButton,ResetInput } from '../styles/CommonStyles'
import { AiOutlineSearch } from 'react-icons/ai'

const Search = ({ searchText,setSearchText }) => {
  const searchDom = useRef()
  const inputDom = useRef()

  const handleClick = () => {
    inputDom.current.focus()
  }

  const handleOpen = () => {
    searchDom.current.classList.add(`open`)
  }

  const handleClose = () => {
    if(!searchText)
      searchDom.current.classList.remove(`open`)
  }

  return (
    <Container>
      <StyledSearch ref={searchDom}>
        <OpenSearch onClick={handleClick}>
          <OpenSearchIcon />
        </OpenSearch>
        <Input onChange={e => setSearchText(e.target.value)} onFocus={handleOpen} onBlur={handleClose} ref={inputDom}/>
      </StyledSearch>
    </Container>
  )
}

export default Search

const Container = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
`
const StyledSearch = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all .2s ease-in-out;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 32px;
  padding: 4px 8px 4px 8px;
  font-size: 24px;

  &.open {
    background-color: var(--background);
    color: var(--onBackground);
    width: 220px;
    font-size: 20px;

    input {
      width: 100%;
    }
  }
`
const OpenSearch = styled.button`
  ${ResetButton}
  color: inherit;
  font-size: inherit;
`
const OpenSearchIcon = styled(AiOutlineSearch)`
  color: inherit;

`
const Input = styled.input`
  ${ResetInput}
  color: var(--onBackground);
`
