import styled from 'styled-components'
import { AiOutlineMail,AiOutlinePhone } from 'react-icons/ai'
import { FiMap } from 'react-icons/fi'

import PageLayout from '../layouts/PageLayout'
import { DefaultCard,List,ListItem,P4 } from '../styles/CommonStyles'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
    <PageLayout title="Contact Us">
      <DefaultCard>
        <Container>
          <ContactItem>
            <AiOutlineMail />
            <ContactItemText to="mailto:hello@stashco.ai">hello@stashco.ai</ContactItemText>
          </ContactItem>
          <ContactItem>
            <AiOutlinePhone />
            <ContactItemText to="tel:+447520633884">+44 7520 633884</ContactItemText>
          </ContactItem>
          <ContactItem>
            <FiMap />
            <ContactItemText to="https://goo.gl/maps/Um6BZfq4NcxYARyL9">Gemma House 39, London NW8 8SS, United Kingdom</ContactItemText>
          </ContactItem>
        </Container>
      </DefaultCard>
    </PageLayout>
  )
}

export default Contact

const Container = styled.div`
  ${List}
  justify-content: center;

  @media screen and (min-width: 1400px) {
    --columns: 3;
  }
`

const ContactItem = styled.div`
  ${ListItem}
  align-items: center;
  padding: 32px;
  border: 1px solid var(--dividerOnSurface);
  border-radius: 4px;
  text-align: center;
  color: var(--white);

  @media screen and (min-width: 768px) {
    flex-grow: 1;
    flex-shrink: 1;
  }

  svg {
    font-size: 32px;
    color: var(--light);

    @media screen and (min-width: 768px) {
      font-size: 48px;
    }
  }
`
const ContactItemText = styled(Link)`
  display: flex;
  margin-top: 16px;

  @media screen and (min-width: 768px) {
    margin-top: 24px;
  }
`
