import React,{ useState } from 'react'
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { PrimaryButton } from '../styles/CommonStyles'
import styled from 'styled-components'
import { ROUTES } from '../routes/routes'
import { WEBAPP_URL } from '../data/constants'
import { toast } from 'react-toastify'

export default function CheckoutForm() {
  const stripe = useStripe()
  const elements = useElements()
  const [isFormReady,setIsFormReeady] = useState(false)
  const [isLoading,setIsLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) return

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { "return_url": `${WEBAPP_URL}${ROUTES.PAYMENT_RESULT}` },
    })

    if (error.type === `card_error` || error.type === `validation_error`)
      toast.error(error.message)
    else
      toast.error(`An unexpected error occurred.`)

    setIsLoading(false)
  }

  const paymentElementOptions = { layout: `tabs` }

  return (
    <PaymentForm id="payment-form" onSubmit={handleSubmit} $isFormReady={isFormReady}>
      <PaymentElement id="payment-element" options={paymentElementOptions} onReady={() => setIsFormReeady(true)} />
      {isFormReady && (
        <PaymentButton $loading={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : `Pay now`}
          </span>
        </PaymentButton>
      )}
    </PaymentForm>
  )
}

const PaymentButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 24px;
  background-color: var(--success);
  color: var(--onSuccess);
`
const PaymentForm = styled.form`
  ${p => !p.$isFormReady && `
    min-height: 280px;
  `}
`
