import styled from 'styled-components'
import ProductAddOrUpdate from '../components/ProductAddOrUpdate'
import { useParams } from 'react-router-dom'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'

const EditProduct = () => {
  const { id } = useParams()

  return (
    <PageLayout title="Edit Product">
      <PageContainerDivided>
        <ProductAddOrUpdate id={id} />
      </PageContainerDivided>
    </PageLayout>
  )
}

export default EditProduct
