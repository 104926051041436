import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter } from 'react-router-dom'
import { useColors } from './hooks/useColors'
import { ToastContainer,toast } from 'react-toastify'
import GlobalStyle from './styles/GlobalStyle'
import Router from './routes/Router'
import { useEffect } from 'react'
import { useNetworkStatus } from './hooks/useNetworkStatus'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useSetVenue } from './hooks/useSetVenue'
import { useFirebasePush } from './hooks/useFirebasePush'

const App = () => {
  const isOnline = useNetworkStatus()
  const { initialize,onPushMessage } = useFirebasePush()
  useSetVenue()
  useColors()

  useEffect(() => {
    if(!isOnline)
      toast.warn(`You are disconnected!`,{ toastId: `network`,position: `bottom-right`,autoClose: false,closeButton: false,closeOnClick: false,draggable: false })
    else
      toast.dismiss(`network`)
  },[isOnline])

  useEffect(() => {
    initialize()
    onPushMessage()
  },[initialize,onPushMessage])

  return (
    <BrowserRouter>
      <GlobalStyle />
      <Router/>
      <ToastContainer theme="dark"/>
    </BrowserRouter>
  )
}

export default App
