export const getDepthValue = (x,field,lower) => {
  const keys = field.split(`.`)

  try {
    if(keys.length === 1) {
      const res = x[field]
      return typeof res === `string` && lower ? res.toLowerCase() : res
    }
    else {
      const [,...rest] = keys
      return getDepthValue(x[keys[0]],rest.join(`.`),lower)
    }
  } catch (error) {
    return  
  }
}
