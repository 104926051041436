import React,{ useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCardStyle,IconButton,IconLink,LinkButton,ListItem,PageAdd,PrimaryButtonDestructive,SummaryItem,SummaryList,
} from '../styles/CommonStyles'
import Table from '../components/Table'
import { AiOutlineEdit,AiOutlineDelete,AiOutlinePlusCircle } from 'react-icons/ai'
import { useCollection } from '../hooks/useCollection'
import { deleteDoc,doc } from 'firebase/firestore'
import { db } from '../services/firebase'
import { toast } from 'react-toastify'
import PageLayout from '../layouts/PageLayout'
import { mergeCollections } from '../utils/mergeCollections'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { ConfirmDialog } from '../components/ConfirmDialog'
import { confirmAlert } from 'react-confirm-alert'
import { USER_ROLES } from '../data/constants'

const MobileInventoryCard = ({ id,quantity,product,venue,handleDelete }) => {
  return (
    <OrderListItem key={id}>
      <Image src={product?.thumbnail} />
      <Title>{product?.title}</Title>
      <SummaryList>
        <SummaryItem>
          <span>Quantity</span>
          <div>{quantity}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Venue</span>
          <div>{venue?.name}</div>
        </SummaryItem>
      </SummaryList>
      <ActionContainer>
        <PrimaryButtonDestructive onClick={() => handleDelete(id)}>Delete</PrimaryButtonDestructive>
        <LinkButton style={{ flex: 1 }} to={`/inventory/${id}/edit`}>Edit</LinkButton>
      </ActionContainer>
    </OrderListItem>
  )
}

const Inventory = () => {
  const [products,productsLoaded] = useCollection(`products`)
  const [inventory,inventoryLoaded] = useCollection(`inventory`)
  const [venues,venuesLoaded] = useCollection(`venues`)
  const currentUser = useCurrentUser()
  const [searchText,setSearchText] = useState()
  const [sortField,setSortField] = useState({ field: `product.title`,isReverse: false })

  const handleDelete = id => {
    const onConfirm = async() => {
      try {
        await deleteDoc(doc(db,`inventory`,id))
        toast.success(`Inventory deleted successfully`)
      } catch (error) {
        handleFirebaseErrors(error) 
      }
    }

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDialog title="Delete Inventory" message="Are you sure you want to delete?" confirmText="Yes, Delete it!" onClose={onClose} onConfirm={onConfirm} />
      ),
    })
  }

  const actionButtons = val => {
    return (
      <div className="table-actions">
        <IconLink $circle to={`/inventory/${val}/edit`}><AiOutlineEdit size={18} /></IconLink>
        <IconButton $circle $destructive onClick={() => handleDelete(val)}><AiOutlineDelete size={18} /></IconButton>
      </div>
    )
  }

  const columns = [
    { field: `product.thumbnail`,css: { width: `140px` },type: `image`,title: `Thumbnail`,render: val => <OrderListImage src={val} /> },
    { field: `product.title`,sortable: true,title: `Product Title` },
    { field: `quantity`,sortable: true,title: `Quantity` },
    { field: `venue.name`,sortable: true,title: `Venue` },
    { field: `action`,css: { width: `120px`,position: `flex-end` },params: params => params.row.id,render: actionButtons },
  ]

  const inventoryWithProducts = mergeCollections(
    inventory,
    { collection: products,field: `id`,on: `productId`,name: `product`,single: true },
    { collection: venues,field: `id`,on: `venueId`,name: `venue`,single: true },
  )

  return (
    <PageLayout title="Inventory" search={[searchText,setSearchText]} sort={[sortField,setSortField,columns]}>
      <PageAdd>
        <IconLink to={`/inventory/new`}>
          <AiOutlinePlusCircle size={20} />
          <span>Add new</span>
        </IconLink>
      </PageAdd>
      <OrderList>
        <Table
          loading={!productsLoaded || !inventoryLoaded || !venuesLoaded}
          loadingType="products"
          columns={columns} 
          rows={inventoryWithProducts}
          predicate={({ _row }) => currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === _row.venueId)}
          searchText={searchText}
          sortField={sortField}
          MobileCard={MobileInventoryCard}
          mobileCardFunctions={{ handleDelete }} />
      </OrderList>
    </PageLayout>
  )
}

export default Inventory

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`
const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;

  @media screen and (min-width: 576px) {
    height: 180px;
  }
  
  @media screen and (min-width: 1400px) {
    height: 200px;
  }
`
const Title = styled.h4`
  padding: 16px 0 8px 0;
  margin-bottom: 8px;
  font-size: 18px;
`
const OrderListItem = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const OrderListImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: auto;
  padding-top: 16px;
`
