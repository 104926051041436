import Modal from './Modal'
import { UpdateHangingLocationCard } from './UpdateHangingLocationCard'
import { useNavigate,useSearchParams } from 'react-router-dom'
import { useEffect,useState,memo } from 'react'
import {
  addDoc,collection,doc,getDoc,getDocs,query,setDoc,where, 
} from 'firebase/firestore'
import { db,parseCollectionSnapshot,parseDocSnapshot } from '../services/firebase'
import { toast } from 'react-toastify'
import { HANGING_STATUS,USER_ROLES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { AreaButton,List,ListItem } from '../styles/CommonStyles'
import styled from 'styled-components'
import { BiMap } from 'react-icons/bi'
import { GiHanger } from 'react-icons/gi'

const ScanHanger = memo(() => {
  const [showModal,setShowModal] = useState()
  const [isChangeLocation,setIsChangeLocation] = useState(false)
  const [hangingToReturn,setHangingToReturn] = useState()
  const [searchParams] = useSearchParams()
  const currentUser = useCurrentUser()
  const navigate = useNavigate()

  const hangerId = searchParams.get(`hanger`)

  useEffect(() => {
    (async() => {
      if(!hangerId) return

      if(!currentUser.id)
        return

      const hanger = parseDocSnapshot(await getDoc(doc(db,`hangers`,hangerId)))
      if(!hanger.id)
        return toast.error(`Hanger not found!`)

      const cloakroom = parseDocSnapshot(await getDoc(doc(db,`cloakrooms`,hanger.cloakroomId)))
      if(!cloakroom.id)
        return toast.error(`Cloakroom not found!`)

      if(!(currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === cloakroom.venueId)))
        return toast.error(`You don't have permission for this!`)

      const conditions = [
        where(`hangerId`,`==`,hangerId),
        where(`status`,`!=`,HANGING_STATUS.RETURNED),
        (currentUser.role !== USER_ROLES.ADMIN && where(`venueId`,`in`,currentUser.venues)),
      ].filter(item => item)

      const q = query(collection(db,`hangings`),...conditions)
      const hangings = parseCollectionSnapshot(await getDocs(q))

      if(hangings.length){
        setHangingToReturn(hangings[0].id)
        setShowModal(true)
        return
      }

      const hangingToSave = {
        hangerId,
        venueId: hanger.venueId,
        createdAt: new Date(),
        status: HANGING_STATUS.CREATED,
      }

      try {
        await addDoc(collection(db,`hangings`),hangingToSave)
        toast.success(`Stashed saved successfully`)
        navigate(`/hangings`)
      } catch (error) {
        handleFirebaseErrors(error)
      }
    })()
  },[currentUser.id,currentUser.role,currentUser.venues,hangerId,navigate])

  const handleCloseModal = () => {
    navigate(`/`)
    setShowModal(false)
  }

  const handleReturnStashed = async() => {
    try {
      // TODO: clear pushTokens venueId and hangingId
      await setDoc(doc(db,`hangings`,hangingToReturn),{ status: HANGING_STATUS.RETURNED },{ merge: true })
      handleCloseModal()
      toast.success(`Stashed returned successfully`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  return (
    <Modal title={isChangeLocation ? `Change Location` : `Choose`} showModal={showModal} setShowModal={setShowModal}>
      {isChangeLocation ? (
        <UpdateHangingLocationCard id={hangingToReturn} closeModal={handleCloseModal} />
      ) : (
        <Container>
          <Item onClick={() => setIsChangeLocation(true)}>
            <BiMap />
            <ItemText>Change Location</ItemText>
          </Item>
          <Item onClick={handleReturnStashed}>
            <GiHanger />
            <ItemText>Return Stashed</ItemText>
          </Item>
        </Container> 
      )}
     
    </Modal>
  )
})

ScanHanger.displayName = `ScanHanger`

export { ScanHanger }

const Container = styled.div`
  ${List}
  justify-content: center;

  @media screen and (min-width: 1400px) {
    --columns: 2;
  }
`
const Item = styled(AreaButton)`
  ${ListItem}
  align-items: center;
  padding: 32px;
  border: 1px solid var(--dividerOnSurface);
  border-radius: 4px;
  text-align: center;
  color: var(--white);
  background-color: var(--surface);

  @media screen and (min-width: 768px) {
    flex-grow: 1;
    flex-shrink: 1;
  }

  svg {
    font-size: 32px;
    color: var(--light);

    @media screen and (min-width: 768px) {
      font-size: 48px;
    }
  }
`
const ItemText = styled.div`
  display: flex;
  margin-top: 16px;
  font-size: 16px;

  @media screen and (min-width: 768px) {
    margin-top: 24px;
  }
`
