import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { DefaultCardStyle } from '../styles/CommonStyles'

const Modal = ({ title,children,showModal,setShowModal,scroll }) => {
  if(!showModal) return <></>

  const handleClose = e => {
    setShowModal(false)
  }

  return createPortal(
    <StyledModal>
      <ModalBackdrop onClick={handleClose} />
      <ModalContainer $scroll={scroll}>
        {title && <ModalTitle>{title}</ModalTitle>}
        <ModalContent>
          {children}
        </ModalContent>
      </ModalContainer>
    </StyledModal>,
    document.body,
  )
}

export default Modal

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
`
const ModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdrop);
  backdrop-filter: blur(2px);
`
const ModalContainer = styled.div`
  ${DefaultCardStyle}
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 700px;
  max-height: 70vh;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--dividerOnSurface);

  ${p => p.$scroll && `
    overflow-y: auto;
  `}

  @media screen and (min-width: 576px) {
    padding: 32px;
  }
`
const ModalTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 16px;
`
const ModalContent = styled.div`

`
