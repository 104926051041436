import { Link } from 'react-router-dom'
import styled,{ css } from 'styled-components'

export const H1 = styled.h1`
  color: var(--light);
  text-align: center;
`
export const P3 = styled.p`
  margin-bottom: 32px;
`
export const P4 = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
  padding: 32px 16px 80px 16px;
  background: var(--darkest);

  /* @media screen and (min-width: 576px) {
    padding: 48px 48px 120px 48px;
  } */

  @media screen and (min-width: 992px) {
    padding: 48px 48px 120px 48px;
  }
`
export const ResetButton = css`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
`
export const ResetInput = css`
  background: transparent;
  border: none;
  padding: 0;
  outline: none;

  &:focus {
    border: none;
  }
`
export const IconLinkButtonStyle = css`
  color: var(--white);
  background-color: var(--dividerOnSurface);
`
export const IconLink = styled(Link)`
  ${ResetButton}
  gap: 4px;

  ${p => p.$circle && `
    ${IconLinkButtonStyle}
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 32px;
  `}
`
export const AreaButton = styled.button`
  ${ResetButton}
  flex: 1;
  color: inherit;
  font-size: inherit;
`
export const IconButton = styled.button`
  ${ResetButton}
  ${p => p.$circle && `
    ${IconLinkButtonStyle}
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 4px;
    border-radius: 32px;
  `}
  ${p => p.$destructive && `
    background-color: var(--destructive);
    color: var(--onDestructive);
  `}
`
export const PrimaryButtonCss = css`
  ${ResetButton}
  gap: 8px;
  padding: 12px;
  font-size: 14px;
  color: var(--onPrimaryButton);
  background-color: var(--primaryButton);
  border: 0;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  cursor: pointer;

  ${p => p.$loading && `
    pointer-events: none;
    opacity: 0.75;
  `}

  ${p => p.disabled && `
    pointer-events: none;
    opacity: 0.75;
  `}
`
export const PrimaryButton = styled.button`
  ${PrimaryButtonCss}
  ${p => p.$alignLeft && `
    justify-content: flex-start;

    span {
      width: 100%;
      text-align: center;
      margin-left: -24px;
    }
  `}
`
export const LinkButton = styled(Link)`
  ${PrimaryButtonCss}
`
export const PrimaryButtonDestructive = styled.button`
  ${PrimaryButtonCss}
  background-color: var(--destructive);
  color: var(--onDestructive);
`
export const CircleButton = styled.button`
  align-self: flex-start;
  padding: 0;
  width: 32px;
  height: 32px;
  font-size: 14px;
  color: var(--onPrimaryButton);
  background-color: var(--light);
  border: 0;
  border-radius: 20px;
  cursor: pointer;
  ${p => p.disabled && `opacity: 0.5`}
`
export const DefaultCardStyle = css`
  background-color: var(--surface);
  color: var(--onSurface);
  padding: 16px;
  border-radius: 4px;
  box-shadow: var(--shadow);
`
export const List = css`
  --gap: 16px;
  --columns: 1;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  width: 100%;
  max-width: 1440px;

  @media screen and (min-width: 768px) {
    --columns: 2;
  }

  @media screen and (min-width: 1200px) {
    --columns: 3;
  }

  @media screen and (min-width: 1400px) {
    --columns: 4;
  }
`
export const ListItem = css`
  display: flex;
  flex-direction: column;
  width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
  /* max-width: 420px;

  @media screen and (min-width: 576px) {
    max-width: 100%;
  } */
`
export const SummaryList = styled.div`
`
export const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dividerOnSurface);
  }
`
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1440px;
  `
export const PageContainerDivided = styled(PageContainer)`
  gap: 32px;

  @media (min-width: 1380px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`
export const PageHeaderTitleContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  gap: 8px;
`
export const PageHeaderTitle = styled.h2`
  margin: 0;
`
export const PageHeaderActions = styled.div`
  display: flex;
  gap: 16px;
  color: var(--light);
`
export const PageHeaderAction = styled.div`
  display: flex;
`
export const PageAdd = styled.div`
  align-self: flex-start;
  margin-top: -16px;
  margin-bottom: 16px;
  color: var(--dark);
`
export const DefaultCard = styled.div`
  ${DefaultCardStyle}
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`
export const FormItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: 1200px) {
    max-width: ${p => p.full ? `100%` : `50%`};
  }
`
export const FormLabel = styled.label`
  margin-bottom: 4px;
`
export const FormError = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: var(--error);
`
export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 64px 32px;
  text-align: center;
  color: ${p => p.$error ? `var(--error)` : p.$success ? `var(--success)` : `var(--background)`};

  @media screen and (min-width: 576px) {
    padding: 120px 32px;
  }

  svg {
    font-size: 120px;
  }
`
export const EmptyStateIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
`
export const EmptyStateText = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 24px;
`
export const VerticalSpace = styled.div`
  margin-top: 16px;
`
export const InputError = styled.div`
  color: var(--error);
  /* font-family: Inter; */
  font-size: 16px;
  padding: 5px;
`
export const Input = styled.input`
  width: 100%;
  text-align: ${p => p.centered ? `center` : `left`};
  border: none;
  padding: ${p => p.compact ? `5px` : `12px 8px`};
  border-radius: 4px;
  color: var(--onSurface);
  background-color: var(--surface);
  border: 1px solid var(--dividerOnSurface);

  &:focus{
    outline: none;
  }
`
export const Step = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--dividerOnSurface);
  }

  svg {
    font-size: 22px;
  }
`
export const StepBlue = styled.span`
  display: inline-flex;
  gap: 4px;
  margin: 0 2px;
  color: var(--blue);
`
