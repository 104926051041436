import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useAuth } from '../hooks/useAuth'

export const Logout = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  // TODO: add spinner while logging out...
  useEffect(() => {
    if(auth)
      auth.signOut().then(() => {
        navigate(`/login`)
      })
  },[auth,navigate])
  return (
    <Container>
      logging out...
    </Container>
  )
}

const Container = styled.div`
  display:flex;
`
