import React,{ useState } from 'react'
import styled from 'styled-components'
import { DefaultCardStyle,IconButton,ListItem,PrimaryButton,SummaryItem,SummaryList } from '../styles/CommonStyles'
import Table from '../components/Table'
import { dateFormat,priceFormat } from '../utils/format'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import Modal from '../components/Modal'
import OrderDetailCard from '../components/OrderDetailCard'
import { ORDER_STATUS_ENUM,USER_ROLES } from '../data/constants'
import PageLayout from '../layouts/PageLayout'
import { useCollection } from '../hooks/useCollection'
import { mergeCollections } from '../utils/mergeCollections'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useQueryCollection } from '../hooks/useQueryCollection'

const MobileOrderCard = ({
  id,date,hanging,total,items,status,handleOpen, 
}) => {
  return (
    <OrderListItem key={id}>
      <SummaryList>
        <SummaryItem>
          <span>Date</span>
          <div>{dateFormat(date)}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Total</span>
          <div>{priceFormat(total)}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Items</span>
          <div>{items.reduce((acc,crr) => acc + crr.count,0)}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Venue</span>
          <div>{hanging?.hanger?.cloakroom?.venue?.name}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Location</span>
          <div>{hanging?.hanger?.cloakroom?.number}/{hanging?.hanger?.number}</div>
        </SummaryItem>
        <SummaryItem>
          <span>Status</span>
          <div>{ORDER_STATUS_ENUM[status]}</div>
        </SummaryItem>
      </SummaryList>
      <Button onClick={() => handleOpen(id)}>Details</Button>
    </OrderListItem>
  )
}

export const MyOrders = () => {
  const currentUser = useCurrentUser()
  const [hangings,hangingsLoaded] = useQueryCollection(`hangings`,[[`userId`,`==`,currentUser?.id]])
  const [orders,ordersLoaded] = useQueryCollection(`orders`,[[`hangingId`,`in`,hangings.map(item => item.id)]])
  const [hangers,hangersLoaded] = useCollection(`hangers`)
  const [cloakrooms,cloakroomsLoaded] = useCollection(`cloakrooms`)
  const [venues,venuesLoaded] = useCollection(`venues`)
  const [searchText,setSearchText] = useState()
  const [sortField,setSortField] = useState({ field: `date`,isReverse: true })
  const [selectedOrder,setSelectedOrder] = useState()
  const [showModal,setShowModal] = useState(false)

  const ordersWithVenues = mergeCollections(
    orders,
    { collection: hangings,field: `id`,on: `hangingId`,name: `hanging`,single: true },
    { collection: hangers,field: `id`,on: `hanging.hangerId`,name: `hanging.hanger`,single: true },
    { collection: cloakrooms,field: `id`,on: `hanging.hanger.cloakroomId`,name: `hanging.hanger.cloakroom`,single: true },
    { collection: venues,field: `id`,on: `hanging.hanger.cloakroom.venueId`,name: `hanging.hanger.cloakroom.venue`,single: true },
  )

  const handleOpen = id => {
    const order = ordersWithVenues.find(item => item.id === id)
    setSelectedOrder(order)
    setShowModal(true)
  }

  const actionButtons = val => {
    return (
      <div className="table-actions">
        <IconButton $circle onClick={() => handleOpen(val)} ><AiOutlineInfoCircle size={18} /></IconButton>
      </div>
    )
  }

  const columns = [
    { field: `date`,sortable: true,title: `Date`,render: val => dateFormat(val) },
    { field: `total`,sortable: true,title: `Total`,render: val => priceFormat(val) },
    { field: `items`,sortable: true,title: `Items`,render: val => val.reduce((acc,crr) => acc + crr.count,0) },
    { field: `hanging.hanger.cloakroom.venue.name`,sortable: true,title: `Venue` },
    {
      field: `location`,
      sortable: true,
      title: `Location`,
      params: params => ({ cloakroomNumber: params.row.hanging.hanger.cloakroom.number,hangerNumber: params.row.hanging.hanger.number }),
      render: ({ cloakroomNumber,hangerNumber }) => `${cloakroomNumber}/${hangerNumber}`, 
      sort: ({ cloakroomNumber,hangerNumber }) => +`${cloakroomNumber}${hangerNumber}`,
    },
    {
      field: `status`,
      sortable: true,
      title: `Status`,
      render: val => ORDER_STATUS_ENUM[val],
      sort: val => ORDER_STATUS_ENUM[val],
    },
    { field: `action`,css: { width: `80px`,position: `flex-end` },params: params => params.row.id,render: actionButtons },
  ]

  return (
    <PageLayout title="Orders" search={[searchText,setSearchText]} sort={[sortField,setSortField,columns]}>
      <OrderList>
        <Table
          loading={!hangingsLoaded || !ordersLoaded || !hangersLoaded || !cloakroomsLoaded || !venuesLoaded}
          columns={columns}
          rows={ordersWithVenues} 
          searchText={searchText}
          sortField={sortField}
          MobileCard={MobileOrderCard}
          mobileCardFunctions={{ handleOpen }} />
      </OrderList>
      {selectedOrder ? (
        <Modal scroll title="Order Details" showModal={showModal} setShowModal={setShowModal}>
          <OrderDetailCard {...selectedOrder} userRole={USER_ROLES.USER}/> 
        </Modal>
      ) : <></>}
    </PageLayout>
  )
}

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`
const OrderListItem = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const Button = styled(PrimaryButton)`
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`
