import React from 'react'
import { BsPatchCheck } from 'react-icons/bs'
import styled from 'styled-components'
import { Container,DefaultCard,EmptyStateContainer,EmptyStateIcon,EmptyStateText,LinkButton } from '../styles/CommonStyles'
import { useAuth } from '../hooks/useAuth'

const PaymentSuccess = () => {
  const auth = useAuth()

  return (
    <Container>
      <CartContainer>
        <EmptyStateContainer>
          <BsPatchCheck />
          <EmptyStateText>Payment Successfull</EmptyStateText>
          {auth?.currentUser ? <LinkButton to={`/my-orders`}>Go to Orders Page</LinkButton> : <LinkButton to={`/`}>Go to Home</LinkButton>}
        </EmptyStateContainer>
      </CartContainer>
    </Container>
  )
}

export default PaymentSuccess

const CartContainer = styled(DefaultCard)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
  max-width: 1440px;
  color: var(--success);
  
  @media (min-width: 1380px) {
    flex-direction: row;
    align-items: flex-start;
  }
`
