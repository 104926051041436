import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const Logo = () => {

  const navigate = useNavigate()
  return <Container onClick={() => navigate(`/`)}>Stash AI</Container>
}

const Container = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  color: white;

  &:hover {
    cursor: pointer;
  }
`
