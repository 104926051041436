import PageLayout from '../layouts/PageLayout'
import { DefaultCard,P3,P4 } from '../styles/CommonStyles'

const About = () => {
  return (
    <PageLayout title="About">
      <DefaultCard>
        <P3>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque posuere maximus magna vitae volutpat. Curabitur congue tortor ac sem tempor sagittis. Phasellus at vulputate sem. Nam non quam et elit luctus cursus. Praesent ut ipsum elementum, lobortis lectus sit amet, gravida felis. Duis vel lorem nec magna molestie pretium. Duis sed posuere urna. Ut iaculis elit nec massa aliquet, a interdum neque vulputate. Vivamus nec vestibulum nisi, sit amet vestibulum enim. In at efficitur lorem. Morbi ut venenatis erat, non feugiat sem.
        </P3>
        <P4>
          Praesent a iaculis justo. Curabitur eleifend tempus risus in semper. Nunc eleifend luctus felis ac facilisis. Nulla ut sapien congue, tempus nisi dapibus, varius arcu. Nunc quis felis ligula. Curabitur tincidunt, nibh et finibus condimentum, magna elit porttitor justo, id pharetra nunc dolor ac risus. Aliquam id erat sit amet leo pharetra condimentum. Curabitur in tempor sem. Praesent faucibus mauris felis, sit amet tincidunt purus consequat sed. Cras lacinia eget velit non sollicitudin. Donec finibus, massa euismod dignissim pretium, est odio tempus metus, non tincidunt quam nisi sit amet massa. Etiam a libero nisl. Aliquam vel elementum sapien. Cras porta velit nulla, vitae faucibus massa tempus ac.
        </P4>
        <P4>
          Curabitur mattis ligula nec libero luctus pellentesque. Sed at arcu nec elit tempor tempus nec nec sem. Ut laoreet ut urna eget maximus. Donec malesuada laoreet varius. Sed imperdiet velit ac metus varius lobortis. Nam fermentum turpis at mi convallis bibendum ac in nibh. Integer non nulla id urna mollis consectetur at ac sem. Sed neque arcu, auctor at convallis sit amet, lacinia id ligula. Nam ut accumsan est. Sed a nulla nec lacus molestie congue.
        </P4>
        <P4>
          Suspendisse fringilla cursus odio, et sollicitudin ante semper eu. Maecenas vitae est quis eros auctor pharetra eu a dui. Sed nec tellus semper, finibus ante a, dignissim justo. Duis porta condimentum justo, at rhoncus turpis pulvinar id. Vivamus sed elit sem. Duis iaculis leo urna, non iaculis metus eleifend ac. Aliquam sit amet ex id orci molestie vehicula. Pellentesque id dolor lacinia sem condimentum volutpat pretium sed tellus. Morbi efficitur sollicitudin interdum.
        </P4>
        <P4>
          Pellentesque ornare dignissim dui, sed suscipit ante fermentum in. Suspendisse vehicula, libero non egestas pharetra, ex est maximus sem, ut mattis odio neque eget nunc. Sed sit amet nisl tempus, mollis augue non, blandit enim. Cras eu leo nec massa ultricies condimentum eu non ex. Sed in nunc vitae est accumsan euismod. Nam in eros vel sem elementum dapibus. Mauris id efficitur sapien. Nulla nisi ante, suscipit a ligula sed, interdum semper sapien. In viverra mi at nulla venenatis mattis. Donec et tempus elit, in rhoncus lectus.
        </P4>
      </DefaultCard>
    </PageLayout>
  )
}

export default About
