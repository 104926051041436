import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'
import { DefaultCard,IconButton,PrimaryButton } from '../styles/CommonStyles'

export const BottomAlert = ({ text,handleAction,handleDismiss,buttonContent,Icon }) => {
  return (
    <Container>
      <Content>
        {Icon ? <Icon size="28px" /> : <></>}
        <Text>{text}</Text>
        {buttonContent && handleAction ? <Button onClick={handleAction}>{buttonContent}</Button> : <></>}
      </Content>
      <DismissButton onClick={handleDismiss}><FaTimes /></DismissButton>
    </Container>
  )
}

const Container = styled(DefaultCard)`
  position: fixed;
  bottom: 60px;
  left: 0;
  z-index: 995;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  background-color: var(--backdropDark);
  backdrop-filter: blur(4px);
  border-radius: 0;

  @media screen and (min-width: 576px) {
    bottom: 0;
    left: 190px;
    width: calc(100% - 190px);
  }

  @media screen and (min-width: 992px) {
    left: 240px;
    width: calc(100% - 240px);
  }
`
const Content = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 1600px;
  padding-right: 12px;
`
const Text = styled.span`
  /* line-height: 0; */
  flex-grow: 0;
  /* width: 200px; */
`
const Button = styled(PrimaryButton)`
  padding: 4px 12px;
  margin-left: auto;
  font-size: 12px;
  border-radius: 100px;
  background-color: var(--success);
  align-self: flex-end;
`
const DismissButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  opacity: .5;
`
