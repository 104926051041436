import { Link,Navigate,useNavigate,useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'
import {
  Form,PrimaryButton,FormRow,FormLabel,FormError,FormItem,Input,
} from '../styles/CommonStyles'
import { confirmPasswordReset,getAuth,sendPasswordResetEmail } from 'firebase/auth'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { toast } from 'react-toastify'
import { AUTH_ACTION_MODES } from '../data/constants'

const schema = yup.object({ 
  password: yup.string().required(`This field is required`),
  passwordConfirm: yup.string().required(`This field is required`).oneOf([yup.ref(`password`),null],`Passwords must match`), 
}).required()

export const ResetPassword = () => {
  const { register,handleSubmit,formState: { errors,isSubmitting } } = useForm({ resolver: yupResolver(schema) })
  const auth = getAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const mode = searchParams.get(`mode`)
  const code = searchParams.get(`oobCode`)

  const handleResetPassword = async({ password }) => {
    try {
      await confirmPasswordReset(auth,code,password)
      toast.success(`Password updated!`)
      navigate(`/login`)
    } catch (error) {
      handleFirebaseErrors(error)
    }
  }

  if(mode != AUTH_ACTION_MODES.RESET_PASSWORD)
    return <Navigate to={`/login`} />

  return (
    <Container>
      <CompanyName onClick={() => navigate(`/`)}>
        Stash AI
      </CompanyName>
      <Title>Reset Password</Title>
      <Card>
        <Form onSubmit={handleSubmit(handleResetPassword)}>
          <FormRow>
            <FormItem full>
              <Input placeholder="Password" type="password" {...register(`password`)} />
              <FormError>{errors.password?.message}</FormError>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem full>
              <Input placeholder="Confirm Password" type="password" {...register(`passwordConfirm`)} />
              <FormError>{errors.passwordConfirm?.message}</FormError>
            </FormItem>
          </FormRow>
          <PrimaryButton $loading={isSubmitting}>
            Reset Password
          </PrimaryButton>
        </Form>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 100vh;

  p{
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
`
const Card = styled.div`
  background-color: var(--darkest);
  width: 100%;
  max-width: 350px;
  border-radius: 1em;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const CompanyName = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`
const Title = styled.div`
  font-size: 28px;
  color: var(--onBackground);
  width: 100%;
  max-width: 350px;
  padding: 16px 30px;
`
