import React,{ useState } from 'react'
import styled from 'styled-components'
import {
  DefaultCardStyle,IconButton,IconLink,LinkButton,ListItem,PageAdd,PrimaryButtonDestructive, 
} from '../styles/CommonStyles'
import Table from '../components/Table'
import { AiOutlineEdit,AiOutlineDelete,AiOutlinePlusCircle } from 'react-icons/ai'
import { useCollection } from '../hooks/useCollection'
import { deleteDoc,doc } from 'firebase/firestore'
import { db } from '../services/firebase'
import { toast } from 'react-toastify'
import PageLayout from '../layouts/PageLayout'
import { mergeCollections } from '../utils/mergeCollections'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { USER_ROLES } from '../data/constants'
import { handleFirebaseErrors } from '../utils/handleFirebaseErrors'
import { ConfirmDialog } from '../components/ConfirmDialog'
import { confirmAlert } from 'react-confirm-alert'

const MobileCloakroomCard = ({ id,number,venue,handleDelete }) => {
  return (
    <OrderListItem key={id}>
      <Title>{venue?.name}</Title>
      <Price>{number}</Price>
      <ActionContainer>
        <PrimaryButtonDestructive onClick={() => handleDelete(id)}>Delete</PrimaryButtonDestructive>
        <LinkButton style={{ flex: 1 }} to={`/cloakrooms/${id}/edit`}>Edit</LinkButton>
      </ActionContainer>
    </OrderListItem>
  )
}

export const Cloakrooms = () => {
  const [cloakrooms,cloakroomsLoaded] = useCollection(`cloakrooms`)
  const [venues,venuesLoaded] = useCollection(`venues`)
  const currentUser = useCurrentUser()
  const [searchText,setSearchText] = useState()
  const [sortField,setSortField] = useState({ field: `venue.name`,isReverse: false })

  const handleDelete = id => {
    const onConfirm = async() => {
      try {
        await deleteDoc(doc(db,`cloakrooms`,id))
        toast.success(`Cloakroom deleted successfully`)
      } catch (error) {
        handleFirebaseErrors(error) 
      }
    }

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDialog title="Delete Cloakroom" message="Are you sure you want to delete?" confirmText="Yes, Delete it!" onClose={onClose} onConfirm={onConfirm} />
      ),
    })
  }

  const actionButtons = val => {
    return (
      <div className="table-actions">
        <IconLink $circle to={`/cloakrooms/${val}/edit`}><AiOutlineEdit size={18} /></IconLink>
        <IconButton $circle $destructive onClick={() => handleDelete(val)}><AiOutlineDelete size={18} /></IconButton>
      </div>
    )
  }

  const columns = [
    { field: `venue.name`,sortable: true,title: `Venue` },
    { field: `number`,sortable: true,title: `Number` },
    { field: `action`,css: { width: `120px`,position: `flex-end` },params: params => params.row.id,render: actionButtons },
  ]

  const cloakroomsWithVenue = mergeCollections(cloakrooms,{ collection: venues,field: `id`,on: `venueId`,name: `venue`,single: true })

  return (
    <PageLayout title="Cloakrooms" search={[searchText,setSearchText]} sort={[sortField,setSortField,columns]}>
      <PageAdd>
        <IconLink to={`/cloakrooms/new`}>
          <AiOutlinePlusCircle size={20} />
          <span>Add new</span>
        </IconLink>
      </PageAdd>
      <OrderList>
        <Table
          loading={!cloakroomsLoaded || !venuesLoaded}
          loadingType="venues"
          columns={columns} 
          rows={cloakroomsWithVenue}
          predicate={({ _row }) => currentUser.role === USER_ROLES.ADMIN || currentUser.venues?.find(venue => venue === _row.venueId)}
          searchText={searchText}
          sortField={sortField}
          MobileCard={MobileCloakroomCard}
          mobileCardFunctions={{ handleDelete }} />
      </OrderList>
    </PageLayout>
  )
}

const OrderList = styled.div`
  display: flex;
  flex-direction: column;
`
const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;

  @media screen and (min-width: 576px) {
    height: 180px;
  }
  
  @media screen and (min-width: 1400px) {
    height: 200px;
  }
`
const Title = styled.h4`
  padding: 8px 0 8px 0;
  margin-bottom: 8px;
  font-size: 18px;
`
const Price = styled.div`
  font-size: 20px;
  line-height: 1;
  margin-bottom: 32px;
`
const OrderListItem = styled.div`
  ${DefaultCardStyle}
  ${ListItem}
`
const OrderListImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-top: auto;
`
