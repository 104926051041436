import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { HangerAddOrUpdate } from '../components/HangerAddOrUpdate'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'
import { CloakroomAddOrUpdate } from '../components/CloakroomAddOrUpdate'

export const EditCloakroom = () => {
  const { id } = useParams()

  return (
    <PageLayout title="Edit Cloakroom">
      <PageContainerDivided>
        <CloakroomAddOrUpdate id={id} />
      </PageContainerDivided>
    </PageLayout>
  )
}
