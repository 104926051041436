import styled from 'styled-components'
import InventoryAddOrUpdate from '../components/InventoryAddOrUpdate'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'

const NewInventory = () => {
  return (
    <PageLayout title="New Inventory">
      <PageContainerDivided>
        <InventoryAddOrUpdate />
      </PageContainerDivided>
    </PageLayout>
  )
}

export default NewInventory

