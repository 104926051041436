import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import InventoryAddOrUpdate from '../components/InventoryAddOrUpdate'
import PageLayout from '../layouts/PageLayout'
import { PageContainerDivided } from '../styles/CommonStyles'

const EditInventory = () => {
  const { id } = useParams()

  return (
    <PageLayout title="Edit Inventory">
      <PageContainerDivided>
        <InventoryAddOrUpdate id={id} />
      </PageContainerDivided>
    </PageLayout>
  )
}

export default EditInventory
