import { atom,useAtom } from 'jotai'
import _ from 'lodash'
import { doc,onSnapshot } from 'firebase/firestore'
import { db,parseDocSnapshot } from '../services/firebase'

const docsAtom = atom({})

export const docSubscriptions = {}

export const useDoc = path => {
  const [docs,setDocs] = useAtom(docsAtom)
  const [collection,id] = path.split(`/`)
  if(!collection || !id || id === `undefined`) return {}
  if(docSubscriptions[path]) return docs[path] || {}
  docSubscriptions[path] = onSnapshot(doc(db,collection,id),snapshot => {
    console.log(`---GOT DOC SNAPSHOT ${collection} ${id}---`)
    setDocs(s => {
      const output = _.cloneDeep(s)
      output[path] = parseDocSnapshot(snapshot)
      return output
    })
  })
  return docs[path] || {}
}
