export const COLORS = {
  background: `black`,
  onBackground: `#fff`,
  dividerOnBackground: `rgba(255, 255, 255, 0.2)`,
  text: `#ffffff`,
  dark: `#7c8502`,
  light: `#a2ae05`,
  white: `#fff`,
  onWhite: `#000`,
  error: `#ff0000`,
  darkest: `#47534a`,
  dividerOnDarkest: `rgba(0, 0, 0, 0.2)`,
  success: `rgba(61, 234, 45, 0.75)`,
  onSuccess: `#000`,
  surface: `rgba(0, 0, 0, 0.95)`,
  onSurface: `#fff`,
  dividerOnSurface: `rgba(255, 255, 255, 0.25)`,
  destructive: `rgba(255, 50, 50, 0.5)`,
  onDestructive: `#fff`,
  dividerOnDestructive: `rgba(255, 255, 255, 0.2)`,
  backdrop: `rgba(0, 0, 0, 0.25)`,
  backdropDark: `rgba(0, 0, 0, 0.65)`,
  primaryButton: `rgba(255, 255, 255, 0.75)`,
  onPrimaryButton: `#000`,
  shadow: `0 0 10px rgba(0, 0, 0, 0.1)`,
  blue: `#007AFF`,
}
