import { useLocation,useNavigate,useRoutes,useSearchParams } from 'react-router-dom'
import { Landing } from '../pages/Landing'
import { Home } from '../pages/Home'
// import { MainLayout } from '../components/MainLayout'
import Login from '../components/Login'
import NotFound from '../pages/404'
import { ProtectedRoute } from '../components/ProtectedRoute'
import { Profile } from '../pages/Profile'
import Orders from '../pages/Orders'
import Products from '../pages/Products'
import NewProduct from '../pages/NewProduct'
import { useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { useAuth } from '../hooks/useAuth'
import { Logout } from '../components/Logout'
import Cart from '../pages/Cart'
import Checkout from '../pages/Checkout'
import { MainLayout } from '../layouts/MainLayout'
import EditProduct from '../pages/EditProduct'
import PaymentSuccess from '../pages/PaymentSuccess'
import About from '../pages/About'
import Legal from '../pages/Legal'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import Contact from '../pages/Contact'
import Register from '../components/Register'
import Inventory from '../pages/Inventory'
import NewInventory from '../pages/NewInventory'
import EditInventory from '../pages/EditInventory'
import { MyOrders } from '../pages/MyOrders'
import { Venues } from '../pages/Venues'
import { NewVenue } from '../pages/NewVenue'
import { EditVenue } from '../pages/EditVenue'
import { Users } from '../pages/Users'
import { NewHanger } from '../pages/NewHanger'
import { EditHanger } from '../pages/EditHanger'
import { Hangers } from '../pages/Hangers'
import { GuestGuard } from '../components/GuestGuard'
import { Hangings } from '../pages/Hangings'
import { MyHangings } from '../pages/MyHangings'
import { PaymentResult } from '../pages/PaymentResult'
import { NewHanging } from '../pages/NewHanging'
import { EditHanging } from '../pages/EditHanging'
import { ForgotPassword } from '../components/ForgotPassword'
import { ResetPassword } from '../components/ResetPassword'
import { Cloakrooms } from '../pages/Cloakrooms'
import { NewCloakroom } from '../pages/NewCloakroom'
import { EditCloakroom } from '../pages/EditCloakroom'

const Router = () => {
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0,0)
  },[location.pathname])

  return useRoutes([
    { path: `/`,element: <MainLayout><Home/></MainLayout> },
    { path: `/login`,element: <GuestGuard><Login /></GuestGuard> },
    { path: `/register`,element: <GuestGuard><Register /></GuestGuard> },
    { path: `/forgot-password`,element: <GuestGuard><ForgotPassword /></GuestGuard> },
    { path: `/__/auth/action`,element: <GuestGuard><ResetPassword /></GuestGuard> },
    { path: `/about`,element: <MainLayout><About /></MainLayout> },
    { path: `/legal`,element: <MainLayout><Legal /></MainLayout> },
    { path: `/privacy`,element: <MainLayout><PrivacyPolicy /></MainLayout> },
    { path: `/contact`,element: <MainLayout><Contact /></MainLayout> },
    { path: `/profile`,element: <ProtectedRoute><MainLayout><Profile/></MainLayout></ProtectedRoute> },
    { path: `/orders`,element: <ProtectedRoute><MainLayout><Orders/></MainLayout></ProtectedRoute> },
    { path: `/my-orders`,element: <ProtectedRoute><MainLayout><MyOrders/></MainLayout></ProtectedRoute> },
    { path: `/payment-result`,element: <MainLayout><PaymentResult/></MainLayout> },
    {
      path: `/products`,
      element: <ProtectedRoute><MainLayout /></ProtectedRoute>,
      children: [
        { element: <Products/>,index: true },
        { path: `/products/new`,element: <NewProduct/> },
        { path: `/products/:id/edit`,element: <EditProduct/> },
      ], 
    },
    {
      path: `/inventory`,
      element: <ProtectedRoute><MainLayout /></ProtectedRoute>,
      children: [
        { element: <Inventory/>,index: true },
        { path: `/inventory/new`,element: <NewInventory/> },
        { path: `/inventory/:id/edit`,element: <EditInventory/> },
      ], 
    },
    {
      path: `/cloakrooms`,
      element: <ProtectedRoute><MainLayout /></ProtectedRoute>,
      children: [
        { element: <Cloakrooms/>,index: true },
        { path: `/cloakrooms/new`,element: <NewCloakroom/> },
        { path: `/cloakrooms/:id/edit`,element: <EditCloakroom/> },
      ], 
    },
    {
      path: `/hangers`,
      element: <ProtectedRoute><MainLayout /></ProtectedRoute>,
      children: [
        { element: <Hangers/>,index: true },
        { path: `/hangers/new`,element: <NewHanger/> },
        { path: `/hangers/:id/edit`,element: <EditHanger/> },
      ], 
    },
    {
      path: `/hangings`,
      element: <ProtectedRoute><MainLayout /></ProtectedRoute>,
      children: [
        { element: <Hangings/>,index: true },
        { path: `/hangings/new`,element: <NewHanging/> },
        { path: `/hangings/:id/edit`,element: <EditHanging/> },
      ], 
    },
    { path: `/stashed`,element: <ProtectedRoute><MainLayout><MyHangings/></MainLayout></ProtectedRoute> },
    {
      path: `/venues`,
      element: <ProtectedRoute><MainLayout /></ProtectedRoute>,
      children: [
        { element: <Venues/>,index: true },
        { path: `/venues/new`,element: <NewVenue/> },
        { path: `/venues/:id/edit`,element: <EditVenue/> },
      ], 
    },
    {
      path: `/users`,
      element: <ProtectedRoute><MainLayout /></ProtectedRoute>,
      children: [
        { element: <Users/>,index: true },
      ], 
    },
    { path: `/logout`,element: <Logout /> },
    { path: `/cart`,element: <MainLayout><Cart/></MainLayout> },
    { path: `/checkout`,element: <MainLayout><Checkout/></MainLayout> },
    { path: `*`,element: <MainLayout><NotFound /></MainLayout> },
  ])
}

export default Router
