import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import { NavBar } from '../components/Navbar'
import { Sidebar } from '../components/Sidebar'
import { useFirebasePush,usePushPermission } from '../hooks/useFirebasePush'
import { Step,StepBlue } from '../styles/CommonStyles'
import { useShowNotificationModal } from '../hooks/useShowNotificationModal'
import { MdIosShare } from 'react-icons/md'
import { BsPlusSquare } from 'react-icons/bs'
import { AiOutlineNotification } from 'react-icons/ai'
import { BottomAlert } from '../components/BottomAlert'
import { isChromeOnIOS,isIOS } from '../utils/detectBrowsers'
import { useShowAddHomeScreenModal } from '../hooks/useShowAddHomeScreenModal'
import { useState } from 'react'
import Modal from '../components/Modal'

export const MainLayout = ({ children }) => {
  const [showNotificationModal,setShowNotificationModal] = useShowNotificationModal()
  const [showAddHomeScreenModal,setShowAddHomeScreenModal] = useShowAddHomeScreenModal()
  const [showModal,setShowModal] = useState(false)
  const { isPushSupported,token,permission,requestPermission,onPushMessage,loading } = useFirebasePush()
  const [pushPermission] = usePushPermission()

  const handleEnable = async() => {
    await requestPermission()
  }

  const isStandAlone = (window.navigator.standalone || window.matchMedia(`(display-mode: standalone)`).matches)

  const isTurnOnNotificationModalOpened = isPushSupported && ((!token || pushPermission === `default`) && showNotificationModal && !loading)
  const isAddToHomeScreenModalOpened = !isStandAlone && isIOS() && showAddHomeScreenModal

  return (
    <MainLayoutContainer>
      <NavBar />
      <Container>
        <Sidebar/>
        <Content>
          {children ? children : <Outlet />}

          {isTurnOnNotificationModalOpened || isAddToHomeScreenModalOpened ? <Space /> : <></>}
        </Content>
      </Container>
      {isTurnOnNotificationModalOpened ?
        <BottomAlert text="Turn on notifications" Icon={AiOutlineNotification} handleAction={handleEnable} handleDismiss={() => setShowNotificationModal(false)} buttonContent={pushPermission && pushPermission !== `default` ? pushPermission : `Enable`} />
        : <></>}
      {isAddToHomeScreenModalOpened ?
        <BottomAlert text="Add Stash to your Home Screen to enable notifications!" Icon={AiOutlineNotification} handleAction={() => setShowModal(true)} handleDismiss={() => setShowAddHomeScreenModal(false)} buttonContent="Add" />
        : <></>}
      {isChromeOnIOS() ? (
        <Modal showModal={showModal} setShowModal={setShowModal}>
          <Step>1. Tap the <StepBlue><MdIosShare /></StepBlue> (Share) button in the top right corner.</Step>
          <Step>2. Tap the <StepBlue>Add to Home Screen <BsPlusSquare /></StepBlue> option.</Step>
          <Step>3. Tap the <StepBlue>Add</StepBlue> button in the top right corner.</Step>
        </Modal>
      ) : (
        <Modal showModal={showModal} setShowModal={setShowModal}>
          <Step>1. Tap the <StepBlue><MdIosShare /></StepBlue>(Share) button on your browser.</Step>
          <Step>2. Tap the <StepBlue>Add to Home Screen <BsPlusSquare /></StepBlue> option.</Step>
          <Step>3. Tap the <StepBlue>Add</StepBlue> button in the top right corner.</Step>
        </Modal>
      )}
    </MainLayoutContainer>
  )
}

const MainLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height:100vh;
  padding-top: 50px;
`
const Container = styled.div`
  display: flex;

  @media screen and (min-width: 576px) {
    padding-left: 190px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 240px;
  }
`
const Content = styled.div`
  width: 100%;
`
const Space = styled.div`
  margin-bottom: 110px;
  background-color: red;
`
